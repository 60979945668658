import {useQuery} from '@apollo/client'
import {TVendorResponse, TVendorVariables} from '../../data/graphql/queries/people/types'
import {GET_VENDOR} from '../../data/graphql/queries/people'
import useToast from '../useToast'

const useVendorData = (vendorId?: string | number) => {
  const {showErrorToast} = useToast()
  const query = useQuery<TVendorResponse, TVendorVariables>(GET_VENDOR, {
    skip: !vendorId,
    variables: {
      vendorId: Number(vendorId),
    },
    onError() {
      showErrorToast('Request Error', 'Unable to Retrieve Vendor Data')
    },
  })

  return {
    query,
    loading: query.loading,
    data: query.data?.transactionalDb?.vendorByVendorId,
  }
}

export default useVendorData
