import {useQuery} from '@apollo/client'
import {
  TPortalPersonListResponse,
  TPortalPersonListVariables,
} from '../../data/graphql/queries/people/types'
import {GET_PORTAL_PERSON_LIST} from '../../data/graphql/queries/people'
import {useParams, useSearchParams} from 'react-router-dom'
import useToast from '../../hooks/useToast'

const useResidentPortalPerson = () => {
  const {showErrorToast} = useToast()
  const params = useParams<{residentId: string}>()
  const [searchParams] = useSearchParams()

  const personProfileId = Number(params.residentId)
  const unitId = searchParams.get('unitId')

  const query = useQuery<TPortalPersonListResponse, TPortalPersonListVariables>(
    GET_PORTAL_PERSON_LIST,
    {
      variables: {
        condition: {
          isPersonAccessActive: true,
          personProfileId: personProfileId,
          ...(unitId ? {unitId: +unitId} : {}),
        },
      },
      onError() {
        showErrorToast('Request Error', 'Unable to Retrieve Person List Data')
      },
    },
  )

  return {
    query,
    loading: query.loading,
    data: query.data?.transactionalDb.allPortalPersonViews.nodes[0],
  }
}

export default useResidentPortalPerson
