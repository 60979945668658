import {createContext, FC, useEffect, useState, useMemo, useCallback} from 'react'
import {TPersonProfile} from '../data/graphql/queries/entities'

export type User = {
  firstName: string
  lastName: string
  email: string
}

type AuthContextData = {
  user: User | null
  personProfile: TPersonProfile | null
  setUser: (value: User | null) => void
  setPersonProfile: (value: TPersonProfile | null) => void
}

export const AuthContext = createContext<AuthContextData>({
  user: null,
  personProfile: null,
  setUser: () => {
    //
  },
  setPersonProfile: () => {
    //
  },
})

interface IProps {
  children: React.ReactNode
}

const readStoredData = () => {
  const result = {
    user: null,
    personProfile: null,
  }

  const user = localStorage.getItem('user')
  const personProfile = localStorage.getItem('personProfile')

  if (user) {
    result.user = JSON.parse(user)
  }

  if (personProfile) {
    result.personProfile = JSON.parse(personProfile)
  }

  return result
}

const AuthContextProvider: FC<IProps> = props => {
  const initialData = readStoredData()
  const [user, setUser] = useState<User | null>(initialData.user)
  const [personProfile, setPersonProfile] = useState<TPersonProfile | null>(
    initialData.personProfile,
  )

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user))

    if (!user?.email) {
      setPersonProfile(null)
      localStorage.setItem('devMode', 'false')
    }
  }, [user])

  useEffect(() => {
    localStorage.setItem('personProfile', JSON.stringify(personProfile))
  }, [personProfile])

  const contextValue = useMemo(() => {
    return {
      user,
      personProfile,
      setUser,
      setPersonProfile,
    }
  }, [user, personProfile])

  return (
    <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
  )
}

export default AuthContextProvider
