import React, {FC} from 'react'
import Card from '../../../components/Card'
import CardBody from '../../../components/Card/CardBody'
import Column from '../../../components/Grid/Column'
import Icon from '../../../components/Icon'
import {IconOptions} from '../../../components/Icon/Icons.d'
import {CardProps} from '../../../components/Card/Card'

type MetricCounterProps = CardProps & {
  iconName: IconOptions
  loading?: boolean
  value: string | number
  label: string
}

const MetricCounter: FC<MetricCounterProps> = ({
  label,
  value = '',
  loading,
  iconName,
  ...props
}) => {
  return (
    <Card padding='none' {...props}>
      <CardBody>
        <div className={'d-flex align-center'}>
          <Icon icon={iconName} theme={'info'} size={'lg'} />
          <Column>
            <h4 className={'flex-grow-1 no-margin color-black'}>
              {loading ? '...' : value}
            </h4>
            <p className={'font-size-sm no-margin'}>{label}</p>
          </Column>
        </div>
      </CardBody>
    </Card>
  )
}

export default React.memo(MetricCounter)
