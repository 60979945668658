import {TPersonTypeCode, TResidentTypeCode} from '../../queries/common/types'
import {DeviceMaker, TCommonError, YaleUserType} from './../../types'

export enum AccessScheduleTypes {
  ALWAYS = 'ALWAYS',
  TEMPORARY = 'TEMPORARY',
  RECURRING = 'RECURRING',
  UNKNOWN = 'UNKNOWN',
}

export type TPinAccess = TPermanentPin | TTemporaryPin | TRecurringPin

export type TPermanentPin = {
  accessType: 'ALWAYS'
}

export type TTemporaryPin = {
  accessType: 'TEMPORARY'
  accessTimes: string
}

export type TRecurringPin = {
  accessType: 'RECURRING'
  accessTimes: string
  accessRecurrence: string
}

export type TGrantFullAccessToLocksVariables = {
  input: {
    userId: string
    deviceMaker: string
    personType: string
    residentType: string
    lockAccesses: {
      yaleDeviceId: string
      userType: 'USER' | 'MANAGER'
    }[]
  }
}

export type TGrantFullAccessToLocksResponse = {
  lock: {
    grantFullAccessToLocks:
      | {
          failed: {
            userType: string
            yaleDeviceId: string
          }[]
          granted: {
            userType: string
            yaleDeviceId: string
          }[]
        }
      | TCommonError
  }
}

export type TGrantLimitedAccessToLocksResponse = {
  lock: {
    grantLimitedAccessToLocks:
      | {
          created: {
            accessType: 'TEMPORARY' | 'RECURRING'
            yaleDeviceId: string
            ruleId: string
          }[]
          failed: {
            accessType: 'TEMPORARY' | 'RECURRING'
            yaleDeviceId: string
            reason: string
          }[]
        }
      | TCommonError
  }
}

export type TRevokeAccessToLocksResponse = {
  lock: {
    revokeAccessToSelectedLocks:
      | {
          failed: {
            reason: string
            installedDeviceId: string
          }[]
          revoked: {
            installedDeviceId: string
          }[]
        }
      | TCommonError
  }
}

export type TRevokeAccessToLocksVariables = {
  input: {
    deviceMaker: DeviceMaker
    personProfileId: number
    installedDeviceIds: Array<number>
  }
}

export type TLockLimitedAccess = {
  accessType?: Exclude<AccessScheduleTypes, AccessScheduleTypes.ALWAYS>
  deviceId: string
  endTime: string
  schedule: string
  startTime: string
}

export type TGrantLimitedAccessToLocksVariables = {
  input: {
    userId: string
    deviceMaker: string
    personType: string
    residentType: string
    lockAccessRules: TLockLimitedAccess[]
  }
}

export type TLockPin = TPinAccess & {
  deviceId: string
  yaleUserType: YaleUserType
}

export type TSetLockPinsVariables = {
  input: {
    deviceMaker: string
    userId: string
    locksToSet: TLockPin[]
    personType: TPersonTypeCode
    residentType: TResidentTypeCode
  }
}

export type TSetLockPinsResponse = {
  lock: {
    setLockPins:
      | {
          pin: string
          submitted: {
            deviceId: string
            transactionId: string
            accessType: AccessScheduleTypes
          }[]
          failed: {
            reason: string
          }[]
        }
      | TCommonError
  }
}

export type TCancelAsyncTransactionResponse = {
  utility: {
    cancelUnsuccessfulAsyncTransaction:
      | {
          softDeletedAsyncTransactionId: number
        }
      | TCommonError
  }
}

export type TCancelAsyncTransactionVariables = {
  input: {
    asyncTransactionId: number
  }
}

export type TRevokeCommonAreaAccessResponse = {
  lock: {
    revokeAccessFromCommonAreaLocks: {success: boolean} | TCommonError
  }
}

export type TRevokeCommonAreaAccessVariables = {
  input: {
    deviceMaker: string
    personId: number
    propertyId: number
  }
}

export type TGrantCommonAreaAccessResponse = {
  lock: {
    grantAccessToCommonAreaLocks: {
      userId: string
      accessCode?: string
    }
  }
}

export type TGrantCommonAreaAccessVariables = {
  input: {
    deviceMaker: string
    personId: number
    propertyId: number
    effectiveFrom?: string
    effectiveTo?: string
    enableAppAccess: boolean
  }
}
