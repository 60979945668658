// views/UserSettings/UserSettings.tsx

import {useEffect, useState, useContext} from 'react'
import Modal from '../../components/Modal'
import {AuthContext} from '../../contexts/AuthContext'
import {
  getAuth,
  updateEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from 'firebase/auth'
import {formatIdentityError} from '../../functions'
import Button from '../../components/Button'
import InputLabel from '../../components/InputLabel'
import CheckboxGroup from '../../components/CheckboxGroup'
import InputCheckbox from '../../components/InputCheckbox/InputCheckbox'
import InputText from '../../components/InputText/InputText'
import Spinner from '../../components/Spinner'
import './UserSettings.style.scss'
import useUserSettingsUpdate from './usePersonUpdate'

const sampleNotifications: {type: string; isChecked: boolean}[] = []
export interface UserSettingsProps {
  isUserSettingsOpen: boolean
  setUserSettingsOpen: any
  onClick: (e: any) => void
}

const UserSettings = (props: UserSettingsProps) => {
  const auth: any = getAuth()
  const {user, setUser} = useContext(AuthContext)
  const userSettings = useUserSettingsUpdate()

  const [loading, setLoading] = useState<boolean>(false)
  const [isReauthenticating, setReauthenticating] = useState<boolean>(false)
  const [notifications, setNotifications] = useState(sampleNotifications)
  const [errorMessage, setErrorMessage] = useState({
    code: '',
    success: false,
  })
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  })

  useEffect(() => {
    if (user) {
      setFormState(prevState => ({
        ...prevState,
        firstName: user.firstName,
        lastName: user.lastName,
        email: auth.currentUser?.email,
        password: '',
      }))
    }
  }, [user])

  const handleReauthentication = async () => {
    setLoading(true)

    auth.tenantId = auth.currentUser.tenantId
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      formState.password,
    )

    await reauthenticateWithCredential(auth.currentUser, credential)
      .then(response => {
        handleEditSubmit()
        setErrorMessage({code: 'Changes have been saved!', success: true})
        setReauthenticating(false)
      })
      .catch(error => {
        console.log(error)
        setErrorMessage({code: 'Error re-authenticating!', success: false})
      })

    setLoading(false)
  }

  const handleEditSubmit = async () => {
    if (!user) {
      setErrorMessage({code: 'Failed to get current user data', success: false})
      return
    }

    setLoading(true)

    const originalEmail = auth.currentUser.email
    const orginalFirstName = user.firstName
    const orginalLastName = user.lastName

    const newEmail = formState.email
    const newFirstName = formState.firstName
    const newLastName = formState.lastName

    if (
      originalEmail === newEmail &&
      orginalFirstName === newFirstName &&
      orginalLastName === newLastName
    ) {
      return setLoading(false)
    }

    if (originalEmail !== newEmail) {
      const {isAvailable} = await userSettings.checkEmailAvailability(newEmail)

      if (!isAvailable) {
        setErrorMessage({code: 'Email already exists!', success: false})
        return setLoading(false)
      }

      try {
        await updateEmail(auth.currentUser, newEmail)

        userSettings.updatePerson({
          email: newEmail,
          firstName: newFirstName,
          lastName: newLastName,
        })

        setUser({
          ...user,
          email: newEmail,
          firstName: newFirstName,
          lastName: newLastName,
        })
      } catch (e: any) {
        if (e.code === 'auth/requires-recent-login') {
          setReauthenticating(true)
        }
        setErrorMessage({code: formatIdentityError(e.code), success: false})
      }
    } else if (
      (orginalFirstName !== newFirstName || orginalLastName !== newLastName) &&
      originalEmail === newEmail
    ) {
      await userSettings.updatePerson({
        firstName: newFirstName,
        lastName: newLastName,
      })

      setErrorMessage({code: 'Changes saved!', success: true})
    }

    setLoading(false)
  }

  const handleChangePassword = async () => {
    setLoading(true)

    try {
      await userSettings.requestPasswordUpdate()
      setErrorMessage({code: 'Please check your email!', success: true})
    } catch (error: any) {
      setErrorMessage({code: formatIdentityError(error.code), success: false})
    }

    setLoading(false)
  }

  const handleInputChange = (name: string, value: any) => {
    setFormState({...formState, [name]: value})
  }

  const handleCheckboxClick = (type: string) => {
    const newNotifications = [...notifications]
    const index = newNotifications.findIndex(notification => notification.type === type)
    newNotifications[index].isChecked = !newNotifications[index].isChecked

    setNotifications(newNotifications)
  }

  const clearState = () => {
    if (!user) {
      return
    }

    setFormState({
      firstName: user.firstName,
      lastName: user.lastName,
      email: auth.currentUser.email,
      password: '',
    })
    setErrorMessage({code: '', success: false})
    setReauthenticating(false)
  }

  const quitEditing = () => {
    clearState()
    props.setUserSettingsOpen(false)
  }

  return (
    <>
      <Modal
        id='UserSettings'
        width={800 > window.screen.width ? window.screen.width : 800}
        padding='none'
        isOpen={props.isUserSettingsOpen}
        iconHeader='chevron-left'
        {...(isReauthenticating && {iconTheme: 'default'})}
        modalHeading='horizontal'
        closeModal={quitEditing}
        onIconClick={clearState}
      >
        {!isReauthenticating ? (
          <div className='d-flex user-settings-container'>
            <div className='edit-profile-photo-container'>
              <div className='h6-semibold-16 edit-photo black'>Edit Profile Photo</div>
              <div className='image-container'>
                <div className='photo'></div>
                <Button width='block' theme={'danger'}>
                  Upload
                </Button>
              </div>
              <div className='text'>JPG, GIF or PNG. Max size of 800K</div>
            </div>

            <div className='edit-profile-container'>
              <div className='h6-semibold-16 black label'>Edit Profile</div>
              <form
                id={'user-settings-form'}
                className={'user-settings-form'}
                data-testid={'UserSettingsForm'}
              >
                <InputText
                  id={'update-first-name'}
                  type={'text'}
                  label={'First Name'}
                  value={formState.firstName}
                  onValueChange={v => handleInputChange('firstName', v)}
                />
                <InputText
                  id={'update-last-name'}
                  type={'text'}
                  label={'Last Name'}
                  value={formState.lastName}
                  onValueChange={v => handleInputChange('lastName', v)}
                />
                <InputText
                  id={'update-email'}
                  type={'email'}
                  label={'Email'}
                  value={formState.email}
                  onValueChange={v => handleInputChange('email', v)}
                />
              </form>

              <div className='password-container'>
                <InputLabel>PASSWORD</InputLabel>
                <div className='change-password-link' onClick={handleChangePassword}>
                  Change password
                </div>
                {errorMessage.code &&
                  errorMessage.code !== 'Error re-authenticating!' && (
                    <div
                      className={`color-${
                        errorMessage.success ? 'green' : 'red'
                      } desktop-label-semibold-14 `}
                    >
                      {errorMessage.code}
                    </div>
                  )}
              </div>

              <div className='border' />

              {!!notifications.length && (
                <>
                  <div>
                    <div className='h6-semibold-16 black notification-container'>
                      Notifications
                    </div>
                    <InputLabel>EMAIL ME:</InputLabel>
                    <div className='checkbox-container'>
                      <CheckboxGroup theme='none'>
                        {notifications.map(notification => (
                          <InputCheckbox
                            key={notification.type}
                            label={notification.type}
                            isChecked={notification.isChecked}
                            handleChange={type => handleCheckboxClick(notification.type)}
                          />
                        ))}
                      </CheckboxGroup>
                    </div>
                  </div>
                  <div className='border' />
                </>
              )}

              <div className='button-container'>
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    <Button width='block' onClick={handleEditSubmit} disabled={loading}>
                      Save
                    </Button>
                    <Button
                      width='block'
                      theme={'outline'}
                      disabled={loading}
                      onClick={quitEditing}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className='d-flex user-settings-container reauthentication'>
            <div className='text'>
              <div className='h6-semibold-16 black text'>Change Email</div>
              <div className='text body-light-14'>
                Please enter your password to change your email!
              </div>
            </div>
            <div className='edit-profile-container'>
              <form
                id={'reauthentication-form'}
                className={'reauthentication-form'}
                data-testid={'ReauthenticationForm'}
              >
                <InputText
                  disabled
                  id={'reauthenticate-email'}
                  type={'text'}
                  label={'Email'}
                  value={auth.currentUser.email}
                />
                <InputText
                  id={'reauthenticate-password'}
                  type={'password'}
                  label={'Password'}
                  suffixIcon='eye'
                  value={formState.password}
                  onValueChange={v => handleInputChange('password', v)}
                />
              </form>
              {errorMessage.code === 'Error re-authenticating!' && (
                <div
                  className={`color-${
                    errorMessage.success ? 'green' : 'red'
                  } desktop-label-semibold-14 reauthenicate-error`}
                >
                  {errorMessage.code}
                </div>
              )}
              <div className='button-container'>
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    <Button
                      width='block'
                      onClick={handleReauthentication}
                      disabled={loading}
                    >
                      Submit
                    </Button>
                    <Button
                      width='block'
                      theme={'outline'}
                      disabled={loading}
                      onClick={clearState}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}

export default UserSettings
