import {useCallback, useContext, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import CellWithAvatar from '../../../components/DataGrid/CellWithAvatar/CellWithAvatar'
import {SortOptionsItem, useQueryOptions} from '../../../hooks/useQueryOptions'

import {ExportTableContext} from '../../../components/ExportTable/ExportTableContextProvider'
import useStaffData from '../../../hooks/data/useStaffData'
import {capitalize, toCommonDateFormat} from '../../../functions'
import StaffFilters, {TStaffFilterFields} from '../../People/Staff/StaffFilters'
import {ResidentPreview} from '../../People/Residents/Residents'
import People from './People'
import {useGetEmptyTableMessage} from '../../../hooks/filters/useGetEmptyTableMessage'
import useTableSort from '../../../hooks/useTableSort'

const PAGE_SIZE = 10

const sortOptions: Required<SortOptionsItem>[] = [
  {sortKey: 'nameWithAvatar:asc', value: 'EMPLOYEE_NAME_ASC', label: 'Name Acs'},
  {sortKey: 'nameWithAvatar:desc', value: 'EMPLOYEE_NAME_DESC', label: 'Name Desc'},
  {sortKey: 'role:asc', value: 'DEPARTMENT_DESCRIPTION_ASC', label: 'Role Asc'},
  {sortKey: 'role:desc', value: 'DEPARTMENT_DESCRIPTION_DESC', label: 'Role Desc'},
  {sortKey: 'property:asc', value: 'PROPERTY_NAME_ASC', label: 'Property Acs'},
  {sortKey: 'property:desc', value: 'PROPERTY_NAME_DESC', label: 'Property Desc'},
  {
    sortKey: 'propertyGroup:asc',
    value: 'CATEGORY_DESCRIPTION_ASC',
    label: 'Property Group Acs',
  },
  {
    sortKey: 'propertyGroup:desc',
    value: 'CATEGORY_DESCRIPTION_DESC',
    label: 'Property Group Desc',
  },
  {sortKey: 'hireDate:asc', value: 'HIRE_DATE_ASC', label: 'Hire Date Asc'},
  {sortKey: 'hireDate:desc', value: 'HIRE_DATE_DESC', label: 'Hire Date Desc'},
]

const Staff = () => {
  const navigate = useNavigate()
  const [staff, setStaff] = useState<any[]>([])

  const {setQuery} = useContext(ExportTableContext)

  const {
    queryOptions,
    upsertQueryOptions,
    debouncedSearchTerm,
    setQueryOptions,
    onChangeNumberOfItems,
  } = useQueryOptions<TStaffFilterFields>({
    limit: PAGE_SIZE,
    page: 1,
    orderBy: ['HIRE_DATE_DESC'],
    filters: {},
  })

  const tableSort = useTableSort(sortOptions, queryOptions.orderBy[0])

  const {
    staff: staffList,
    response: staffResponse,
    variables: queryVariables,
    queryForDownloadTable,
  } = useStaffData(debouncedSearchTerm, queryOptions)

  useEffect(() => {
    if (staffList) {
      const initialRequestData = staffList?.map(staff => {
        const name = capitalize(staff.employeeName || '')

        return {
          nameWithAvatar: <CellWithAvatar name={name} />,
          role: staff.departmentDescription,
          property: staff.propertyName || '—',
          propertyGroup: staff.categoryDescription || '—',
          hireDate: toCommonDateFormat(staff.hireDate) || '—',
          id: staff.personEmployeeId,
        }
      })

      setStaff(initialRequestData)
    } else {
      setStaff([])
    }
  }, [staffList, staffResponse.variables?.offset])

  const onChangeSortOrder = useCallback(
    (value: string) => {
      tableSort.setSortValue(value)
      upsertQueryOptions(prev => ({...prev, orderBy: [value]}))
    },
    [upsertQueryOptions],
  )

  const onChangeSearchQuery = useCallback(
    (searchTerm: string) => {
      upsertQueryOptions(prev => ({
        ...prev,
        searchTerm,
        page: 1,
      }))
    },
    [upsertQueryOptions],
  )

  const onChangePage = useCallback(
    (page: number) => {
      upsertQueryOptions({
        page: page,
      })
    },
    [setQueryOptions],
  )

  const onPressPerson = useCallback(
    (person?: ResidentPreview) => {
      if (person?.id) {
        navigate(`/reports/people/staff/${person.id}`)
      }
    },
    [navigate],
  )

  const onPressRow = useCallback(
    (index: number) => {
      onPressPerson(staff[index])
    },
    [staff, onPressPerson],
  )

  const onSubmitFilter = useCallback(
    (filters: TStaffFilterFields) => {
      setQueryOptions(prev => ({
        ...prev,
        page: 1,
        filters,
      }))
    },
    [setQueryOptions],
  )

  const dataForTableQuery = useCallback(async () => {
    try {
      const query = await queryForDownloadTable()

      const staff = query.data?.transactionalDb.allPortalEmployeeViews.nodes

      const tableData = staff.map(staff => {
        const name = capitalize(staff.employeeName || '-')
        return Object.values({
          name: name,
          role: staff.departmentDescription || '—',
          property: staff.propertyName || '—',
          propertyGroup: staff.categoryDescription || '—',
          hireDate: toCommonDateFormat(staff.hireDate) || '—',
        })
      })

      tableData.unshift(['Name', 'Role', 'Property', 'Property Goup', 'Hire Date'])

      return tableData
    } catch (error) {
      console.error(error)
    }
  }, [queryForDownloadTable])

  useEffect(() => {
    setQuery(dataForTableQuery as () => Promise<string[][]>)
  }, [dataForTableQuery, queryVariables, setQuery])

  useEffect(() => {
    if (queryOptions.orderBy?.[0] !== tableSort.value) {
      upsertQueryOptions(prev => ({...prev, orderBy: [tableSort.value]}))
    }
  }, [tableSort.value])

  const emptyStaffTable = useGetEmptyTableMessage(queryOptions, {
    query: `Sorry, no matches found by "${queryOptions.searchTerm}".`,
    filter: `Sorry, no matches found by your filters.`,
    filtersAndQuery: `Sorry, no matches found by "${queryOptions.searchTerm}" and filters.`,
    default: 'Staff table is empty...',
  })

  return (
    <People
      onChangeNumberOfItems={onChangeNumberOfItems}
      upsertQueryOptions={upsertQueryOptions}
      onTypeSearchField={onChangeSearchQuery}
      onPressRow={onPressRow}
      Filters={StaffFilters}
      queryOptions={queryOptions}
      tableProps={{
        order: tableSort.order,
        selectedColumn: tableSort.column,
        selectedColumnChange: tableSort.setSortColumn,
        rows: staff,
        columns: [
          {name: 'Name', key: 'nameWithAvatar', sortable: true},
          {name: 'Role', key: 'role', sortable: true},
          {name: 'Property', key: 'property', sortable: true},
          {name: 'Property Group', key: 'propertyGroup', sortable: true},
          {name: 'Hire Date', key: 'hireDate', sortable: true},
        ],
      }}
      count={staffResponse.data?.transactionalDb.allPortalEmployeeViews.totalCount || 0}
      loading={staffResponse.loading}
      className={'Staff'}
      testId={'StaffReports'}
      searchPlaceholder={'Search staff'}
      onChangeSortOrder={onChangeSortOrder}
      onSubmitFilter={onSubmitFilter}
      sortOptions={sortOptions}
      emptyTableComponent={emptyStaffTable}
    />
  )
}

export default Staff
