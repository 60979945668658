import {ApolloLink} from '@apollo/client'
import {getAuth, signOut} from 'firebase/auth'
import ApolloClientUtils from './apollo.utils'

export const personProfileIdLink = new ApolloLink((operation, forward) => {
  const enhance = async () => {
    try {
      const context = operation.getContext()
      const headers = context.headers

      const profile = localStorage.getItem('personProfile')

      if (profile) {
        const personProfileId = JSON.parse(profile).personProfileId

        operation.setContext({
          headers: {
            ...headers,
            'person-profile-id': personProfileId,
          },
        })

        return forward(operation)
      }

      throw new Error('Exepected person profile not found')
    } catch (e) {
      signOut(getAuth())
      localStorage.setItem('user', JSON.stringify({}))
    }
  }

  return ApolloClientUtils.toObservable(enhance).flatMap(() => forward(operation))
})
