import {useMemo} from 'react'
import useCurrentProfile from './useCurrentProfile'

const useUserAccess = () => {
  const profile = useCurrentProfile()

  const result = useMemo(
    () => ({
      loading: false,
      properties: profile ? [profile.propertyId] : [],
    }),
    [profile],
  )

  return result
}

export default useUserAccess
