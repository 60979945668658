import {useEffect, useMemo, useState} from 'react'
import useAccessPoints, {TAccessPointsValue} from '../useAccessPoints'
import {TAccessPointDevices, TDeviceItem, TDevices} from './InstallerAccessPoints'
import {getInventoryId} from '../../../functions/lock.functions'
import usePropertyDevices from '../../../components/PropertyDevices/usePropertyDevices'
import useUnavailableDevices from '../../../hooks/data/useUnavailableDevices'

const accessPointDevices: TAccessPointDevices = {
  unitId: '',
  buildingId: '',
  propertyId: '',
  data: [],
}

type TStructure = {propertyId?: string; buildingId?: string; unitId?: string}

type Props = {
  value: TAccessPointsValue<TDevices>
  vendorUserId?: number
  defaultDevices: TDevices
  onChange: (value: TAccessPointsValue<TDevices>) => void
  getUnitDevicesByUnitId: (unitId: string) => TDevices
}

const useInstallerAccessPoints = ({
  defaultDevices,
  value,
  vendorUserId,
  onChange,
  getUnitDevicesByUnitId,
}: Props) => {
  const {
    property: activeProperty,
    building: activeBuilding,
    properties: propertiesList,
    isBuildingSelected,
    isPropertySelected,
    toggleUnit,
    toggleProperty,
    toggleBuilding,
    selectProperty,
    selectBuilding,
    updateCustomData: setCustomDevices,
  } = useAccessPoints<TDevices>({
    value,
    getStructureSpecificData: getStructureDevices,
    defaultData: defaultDevices,
    onChange,
  })

  const {devicesByInventoryId} = useUnavailableDevices(vendorUserId)
  const {unitClassTypeDevices} = usePropertyDevices()

  const buildingUnits = useMemo(() => {
    const result =
      activeBuilding?.unitsByBuildingId?.nodes?.map(({unitId}) => unitId) || []

    return result as string[]
  }, [activeBuilding?.unitsByBuildingId?.nodes])

  const [isDeviceModalOpen, setDevicesModalOpenFlag] = useState(false)
  const [modalDevices, setModalDevices] =
    useState<TAccessPointDevices>(accessPointDevices)

  const [modalStructure, setModalStructure] = useState<Required<TStructure>>({
    unitId: '',
    buildingId: '',
    propertyId: '',
  })

  useEffect(() => {
    if (propertiesList?.length) {
      const property = propertiesList[0]
      selectProperty(property)

      if (property.buildingsByPropertyId.nodes.length) {
        selectBuilding(property.buildingsByPropertyId.nodes[0])
      }
    }
  }, [propertiesList, selectBuilding, selectProperty])

  const closeDevicesModal = () => {
    setDevicesModalOpenFlag(false)
    setModalStructure({
      unitId: '',
      buildingId: '',
      propertyId: '',
    })
  }

  const getDeviceKey = (device?: Pick<TDeviceItem, 'device' | 'location'>) => {
    return `${device?.device.classTypeId}:${device?.location.code}`
  }

  const openDevicesModal = (structure: TStructure) => {
    const {unitId, buildingId, propertyId} = structure

    setModalStructure({
      unitId: unitId || '',
      buildingId: buildingId || activeBuilding?.buildingId || '',
      propertyId: propertyId || activeProperty?.propertyId || '',
    })

    setDevicesModalOpenFlag(true)
  }

  function getStructureDevices(structure: TStructure, allDevices: TDevices) {
    if (structure.unitId) {
      return getOnlyUnitDevices(structure?.unitId, allDevices)
    } else if (structure.buildingId) {
      return getOnlyBuildingDevices(allDevices)
    }

    return allDevices
  }

  const getOnlyBuildingDevices = (allDevices: TDevices) => {
    const buildingDevices = buildingUnits.reduce<Record<string, TDeviceItem>>(
      (result, unitId) => {
        const availableDevices = unitClassTypeDevices[unitId]

        allDevices.forEach(item => {
          const key = getDeviceKey(item)
          if (availableDevices?.includes(key)) {
            result[key] = item
          }
        })

        return result
      },
      {},
    )

    return Object.values(buildingDevices)
  }

  const getOnlyUnitDevices = (unitId: string, allDevices: TDevices) => {
    const checkedDevices = allDevices.reduce<string[]>((result, device) => {
      if (device.checked) {
        result.push(getDeviceKey(device))
      }

      return result
    }, [])

    const unitDevices = getUnitDevicesByUnitId(unitId).map(item => {
      const deviceInventoryId = getInventoryId(
        unitId,
        item.device.typeId,
        item.location.typeId,
      )

      return {
        ...item,
        checked: checkedDevices.includes(getDeviceKey(item)),
        disabled: !!devicesByInventoryId[deviceInventoryId],
      }
    })

    const result = unitDevices

    return result
  }

  const applyCustomDevices = (devices: TDevices, structure: TStructure) => {
    setCustomDevices({
      ...structure,
      data: devices,
    })
    closeDevicesModal()
  }

  const onChangeDevices = (devices: TDevices) => {
    setModalDevices(prev => ({
      ...prev,
      data: devices,
    }))
  }

  useEffect(() => {
    setModalDevices(prev => {
      if (!prev.unitId) {
        return prev
      }

      return {
        ...prev,
        data: prev.data?.map(device => {
          const inventoryId = getInventoryId(
            prev.unitId,
            device.device.typeId,
            device.location.typeId,
          )
          const isDisabledNow = !!devicesByInventoryId[inventoryId]

          return {
            ...device,
            disabled: isDisabledNow,
            checked: device.disabled && !isDisabledNow ? false : device.checked,
          }
        }),
      }
    })
  }, [devicesByInventoryId])

  return {
    modalStructure,

    devices: modalDevices,
    isDeviceModalOpen,

    property: activeProperty,
    building: activeBuilding,
    properties: propertiesList,
    isBuildingSelected,
    isPropertySelected,
    toggleUnit,
    toggleProperty,
    toggleBuilding,
    selectProperty,
    selectBuilding,
    applyCustomDevices,

    openDevicesModal,
    closeDevicesModal,
    setDevices: onChangeDevices,

    getStructureDevices,
  }
}

export default useInstallerAccessPoints
