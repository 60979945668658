import React, {FC} from 'react'
import Badge from '../../../components/Badge'
import Card from '../../../components/Card'
import CardBody from '../../../components/Card/CardBody'
import Row from '../../../components/Grid/Row'
import Meter from '../../../components/Meter'
import Spinner from '../../../components/Spinner'
import useLeaseStats from '../../../hooks/data/useLeaseStats'

const OccupancyCard: FC = () => {
  const leaseData = useLeaseStats()

  if (leaseData.loading) {
    return (
      <Card padding='none'>
        <CardBody>
          <div className={'d-flex justify-center align-center'}>
            <Spinner />
          </div>
        </CardBody>
      </Card>
    )
  }

  return (
    <Card padding='none'>
      <CardBody>
        <div className={'d-flex space-between align-center occupancy'}>
          <h4 className={'no-margin color-black'}>Occupancy</h4>
        </div>

        <div className={'d-flex justify-center'}>
          <div className={'d-flex space-between align-center metrics-occupancy'}>
            <Row>
              <Meter value={Math.round(leaseData.counters.ocupancy)} />
            </Row>
            <Row>
              <div className='text-align-center metrics-occupancy-statistic'>
                <div>
                  <Badge theme={'info'} size={'lg'}>
                    {leaseData.counters.totalUnitsLeased}
                  </Badge>
                  <p>Total Units Leased</p>
                </div>
                <div>
                  <Badge theme={'default'} size={'lg'}>
                    {leaseData.counters.totalUnits}
                  </Badge>
                  <p>Total Units Available</p>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default React.memo(OccupancyCard)
