import {gql} from '@apollo/client'

export const GET_ACCESS_POINTS = gql`
  query AllAccessPoints ($filter: PropertyFilter, $buildingsFilter: BuildingFilter) {
    transactionalDb {
      allProperties(filter: $filter) {
        nodes {
          propertyId
          propertyName
          buildingsByPropertyId(condition: {isActive: true, isDeleted: false}, filter: $buildingsFilter) {
            nodes {
              buildingId
              buildingName
              unitsByBuildingId(condition: {isActive: true, isDeleted: false}) {
                nodes {
                  unitId
                  unitNumber
                  floorPlanId
                  unitType: unitTypeByUnitTypeId {
                    description  
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_PROPERTIES = gql`
  query PropertiesList(
    $filter: PropertyFilter,
    $residentsFilter: PersonAccessFilter, 
    $guestsFilter: PersonAccessFilter,
    $staffCondition: PersonEmployeeCondition
    ) {
    transactionalDb {
      allProperties(filter: $filter) {
        nodes {
          propertyId
          propertyName
          addressByAddressId {
            address1
            address2
            city
            stateId
            zipCode
            stateByStateId {
              code
            }
          }
          residents: personAccessesByPropertyId(filter: $residentsFilter) {
            totalCount
          }
          guests: personAccessesByPropertyId(filter: $guestsFilter) {
            totalCount
          }
          staff: personEmployeesByPropertyId(condition: $staffCondition) {
            nodes {
              personByPersonId {
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`

export const GET_AVAILABLE_PROPERTIES = gql`
  query AvailableProperties($filter: PropertyFilter) {
    db: transactionalDb {
      properties: allProperties(filter: $filter) {
        totalCount
        nodes {
          id: propertyId
          name: propertyName
        }
      }
    }
  }
`

export const GET_COMMON_AREA_DETAILS = gql`
    query CommonAreaDetails($propertyId: BigInt!, $unitId: BigInt!) {
    transactionalDb {
      propertyByPropertyId(propertyId: $propertyId) {
        propertyName
      }
      unitByUnitId(unitId: $unitId) {
        unitNumber
      }
    }
  }
`

export const GET_PROPERTY = gql`
  query PropertyByPropertyId($propertyId: BigInt!) {
    transactionalDb {
      propertyByPropertyId(propertyId: $propertyId) {
        propertyId
        propertyName
        addressByAddressId {
          address1
          address2
          city
          stateId
          zipCode
          stateByStateId {
            code
          }
        }
      }
    }
  }
`

export const GET_UNIT_RECENT_ACTIVITY = gql`
query GetUnitRecentActivity($unitId: BigInt!, $offset: Int, $first: Int, $orderBy: [LockActivityLogsOrderBy!]) {
  transactionalDb {
    unitByUnitId(unitId: $unitId) {
       lockActivityLogsByUnitId(offset: $offset, first: $first, orderBy: $orderBy) {
        nodes {
          action
          reason
          state
          status
          callingUserId
          transactionId
          step
          message
          createdId
          warningLevel
          createdDt
          phone
          lastName
          firstName
          lockId
          email
          userId
          eventId
          eventType
          event
          device
          personId
          timeStamp
          unitId
          tenantUuid
          lockActivityLogId
          nodeId
          otherUserId
          pinSecret
          syncType
          error
          errorName
          originalPayload
        }
        totalCount
      }
    }
  }
}
`

export const GET_UNIT_DETAILS = gql`
  query GetUnitDetails(
    $unitId: BigInt!, 
    $propertyId: BigInt!, 
    $guestsFilter: PortalPersonViewFilter, 
    $residentsCondition: PersonUnitCondition,
    $residentsAccessesCondition: PersonAccessCondition,
    $leaseCondition: LeaseCondition,
    $locksFilter: InstalledDeviceFilter,
    $thermostatsCondition: InstalledDeviceCondition
  ) {
    db: transactionalDb {
      unit: unitByUnitId(unitId: $unitId) {
        unitNumber
        stageCompKey

        unitType: unitTypeByUnitTypeId {
          description
        }

        building: buildingByBuildingId {
          buildingName
          buildingNumber
          addressId
        }

        leases: leasesByUnitId(condition: $leaseCondition) {
          nodes {
            leaseId
            leaseholder: personByLeaseHolderId {
              email
              lastName
              firstName
              mobilePhone
            }
          }
        }

        locks: installedDevicesByUnitId(filter: $locksFilter) {
          totalCount
        }

        thermostats: installedDevicesByUnitId(condition: $thermostatsCondition) {
          totalCount
        }
      }

      property: propertyByPropertyId(propertyId: $propertyId) {
        propertyName
        address: addressByAddressId {
          address1
          address2
          city
          zipCode
          state: stateByStateId {
            description
            code
          }
        }
      }

      residents: allPersonUnits(condition: $residentsCondition) {
        nodes {
          lease: leaseByLeaseId {
            leaseId
            isActive
            isDeleted
            isExpired
            type: leaseTypeByLeaseTypeId {
              code
            }
            moveOutDate
          }

          person: personByPersonId {
            personId
            firstName
            lastName

            accesses: personAccessesByPersonId(condition: $residentsAccessesCondition) {
              nodes {
                personProfileId,
                personAccessId
              }
            }
          }
        }
      }

      guests: allPortalPersonViews(filter: $guestsFilter) {
        nodes {
          personId
          personProfileId
          personName
          unitId
          personAccessId
        }
      }
    }
  }
`

export const GET_LEASE_OVERVIEW = gql`
  query OverviewLeaseData(
    $unitsCondition: UnitCondition, 
    $unitsFilter: UnitFilter, 
    $overdueLeasesFilter: LeaseFilter, 
    $leasedUnitsCondition: LeaseCondition,
    $leasedUnitsFilter: LeaseFilter,
  ) {
    transactionalDb {
      allUnits(condition: $unitsCondition, filter: $unitsFilter) {
        totalCount
      }
      leasedUnits: allLeases(condition: $leasedUnitsCondition, filter: $leasedUnitsFilter) {
        totalCount
        nodes {
          unitId
        }
      }
      overdueLeases: allLeases(filter: $overdueLeasesFilter) {
        nodes {
          currentBalance
        }
      }
    }
  }
`

export const GET_FLOOR_PLANS = gql`
  query AllFloorPlans($orderBy: [FloorPlansOrderBy!], $condition: FloorPlanCondition) {
    transactionalDb {
      allFloorPlans(orderBy: $orderBy, condition: $condition) {
        nodes {
          name
          floorPlanId
          description 
        }
      }
    }
  }
`

export const GET_SERVICE_TICKETS = gql`
    query GetServiceTickets(
      $first: Int, 
      $offset: Int, 
      $orderBy: [ServiceTicketViewsOrderBy!], 
      $filter: ServiceTicketViewFilter,
      $condition: ServiceTicketViewCondition
    ) {
    transactionalDb {
      categoryTypes: allServiceTicketCategoryTypes {
        nodes {
          id: serviceTicketCategoryTypeId
          description
        }
      }
      issueLocationTypes: allServiceTicketIssueLocationTypes{
        nodes {
          id: serviceTicketIssueLocationTypeId
          description
        }
      }
      issueTypes: allServiceTicketIssueTypes {
        nodes {
          id: serviceTicketIssueTypeId
          description
        }
      }
      serviceTickets: allServiceTicketViews(
        first: $first, 
        filter: $filter
        offset: $offset, 
        orderBy: $orderBy, 
        condition: $condition
      ) {
        totalCount
        nodes {
          technicianName
          unitNumber
          serviceComments
          modifiedDt

          categoryTypeId: serviceTicketCategoryTypeId
          locationTypeId: serviceTicketIssueLocationTypeId
          issueTypeId: serviceTicketIssueTypeId
        }
      }
    }
  }
`

export const GET_SERVICE_TICKETS_INFO = gql`
    query GetServiceTicketsInfo {
    transactionalDb {
      categoryTypes: allServiceTicketCategoryTypes {
        nodes {
          id: serviceTicketCategoryTypeId
          description
        }
      }
      issueLocationTypes: allServiceTicketIssueLocationTypes{
        nodes {
          id: serviceTicketIssueLocationTypeId
          description
        }
      }
      issueTypes: allServiceTicketIssueTypes {
        nodes {
          id: serviceTicketIssueTypeId
          description
        }
      }
    }
  }
`

export const GET_COMMON_AREAS_FOR_GROUPS = gql`
  query ListCommonAreaLocksByGroup($input: ListCommonAreaLocksByGroupInput!) {
    lock {
      commonAreas: listCommonAreaLocksByGroup(input: $input) {
        ... on CommonAreaLocksByGroupList {
          vendor {
            lockName
          }
        }
        ... on CommonError {
          code
          message
          status
          source
        }
      } 
    }
  }
`

export const GET_PROPERTY_DEVICES = gql`
  query AllPropertyDevices($filter: DeviceInventoryViewFilter) {
    transactionalDb {
      allUnits(condition: {isDeleted: false}) {
        nodes {
          unitId
          floorPlanId
        }
      }

      allDeviceTypes(condition: {isDeleted: false}) {
        nodes {
          typeId: deviceTypeId
          code
          description
          classTypeId: deviceClassTypeId
        }
      }

      allDeviceLocationTypes(condition: {isDeleted: false}) {
        nodes {
          typeId: deviceLocationTypeId
          description
          code
        }
      }

      allDeviceInventoryViews(filter: $filter) {
        nodes {
          unitId
          deviceTypeId
          deviceLocationTypeId
          installedDeviceId
        }
      }
    }
  }
`

export const GET_PROPERTIES_WITH_BUILDINGS = gql`
  query GetPropertiesWithBuildings(
    $propertiesCondition: PropertyCondition, 
    $buildingsCondition: BuildingCondition
  ) {
    transactionalDb {
      properties: allProperties(condition: $propertiesCondition) {
        nodes {
          id: propertyId
          name: propertyName

          buildings: buildingsByPropertyId(condition: $buildingsCondition) {
            nodes {
              id: buildingId
              name: buildingName
              number: buildingNumber
            }
          }
        }
      }
    }
  }
`

export const GET_DEVICE_MANUFACTURERS = gql`
  query GetAllDeviceManufacturers($condition: DeviceManufacturerTypeCondition) {
    transactionalDb {
      manufacturers: allDeviceManufacturerTypes(condition: $condition) {
        nodes {
          id: deviceManufacturerTypeId
          code,
          description
        }
      }
    }
  }
`
