import {useQuery} from '@apollo/client'
import {useEffect, useMemo, useState} from 'react'
import {CheckboxItem} from '../../components/CollapsibleCheckboxList/CollapsibleCheckboxList'
import {GET_ACCESS_POINTS} from '../../data/graphql/queries/properties'
import {
  TAllAccessPointsResponse,
  TAllAccessPointsVariables,
} from '../../data/graphql/queries/properties/types'
import useUserAccess from '../useUserAccess'
import useToast from '../useToast'

export type TFilterItem = Record<string, Record<'in', number[]>>

type TPropertyItem = CheckboxItem & {
  buildings: TBuidlingItem[]
  containsSelected: boolean
}

type TBuidlingItem = CheckboxItem & {
  units: CheckboxItem[]
  containsSelected: boolean
}

export type TValue = TPropertyItem[]

type TStructureFilterParams = {
  propertyId?: number[]
}

const useStructuresFilter = (
  initialValue?: TFilterItem[],
  options?: TStructureFilterParams,
) => {
  const userAccess = useUserAccess()
  const {showToast} = useToast()

  const [structures, setStructures] = useState<TValue>([])

  const propertiesFilter = {
    in: userAccess.properties,
  }

  if (options?.propertyId) {
    propertiesFilter.in = [...options.propertyId, ...userAccess.properties]
  }

  const accessPointsResponse = useQuery<
    TAllAccessPointsResponse,
    TAllAccessPointsVariables
  >(GET_ACCESS_POINTS, {
    variables: {
      filter: {
        isActive: {
          equalTo: true,
        },
        isDeleted: {
          equalTo: false,
        },
        propertyId: propertiesFilter,
      },
      buildingsFilter: {
        isDeleted: {
          equalTo: false,
        },
        buildingId: {
          notEqualTo: Number(process.env.REACT_APP_DEVS_BUILDING_ID),
        },
      },
    },
    onError() {
      showToast({
        title: 'Request Error',
        message: 'Unable to Retrieve Structures Data',
        type: 'error',
      })
    },
  })

  const propertiesList = accessPointsResponse.data?.transactionalDb.allProperties.nodes

  const defaultValue = useMemo<TValue>(() => {
    if (!propertiesList) {
      return []
    }

    const value: TValue = []
    propertiesList.forEach(property => {
      const propertyInitialValues = initialValue?.filter?.(p =>
        p.propertyId.in.includes(+property.propertyId),
      )

      const containsSelected =
        !!propertyInitialValues?.length &&
        propertyInitialValues.some(p => !!p.buildingId?.in.length)

      const allowedToSelectPropertyIds = !!options?.propertyId?.length

      if (
        allowedToSelectPropertyIds &&
        !options?.propertyId?.includes(+property.propertyId)
      ) {
        return
      }

      value.push({
        id: property.propertyId,
        name: property.propertyName,
        checked: !!propertyInitialValues?.length,
        containsSelected,
        buildings: property.buildingsByPropertyId.nodes.map(building => {
          const buildingInitialValue = propertyInitialValues?.find(b =>
            b.buildingId?.in.includes(+building.buildingId),
          )
          const containsSelected =
            !!buildingInitialValue && !!buildingInitialValue?.unitId?.in.length
          return {
            id: building.buildingId,
            name: building.buildingName,
            checked: !!buildingInitialValue,
            containsSelected,
            units: building.unitsByBuildingId.nodes
              .map(unit => {
                return {
                  id: unit.unitId,
                  name: unit.unitNumber,
                  checked: buildingInitialValue?.unitId?.in.includes(+unit.unitId),
                }
              })
              .sort((a, b) => a.name.localeCompare(b.name)),
          }
        }),
      })
    })

    return value
  }, [initialValue, options?.propertyId, propertiesList])

  useEffect(() => {
    setStructures(defaultValue)
  }, [defaultValue])

  const getSelectedIds = () => {
    const filters: TFilterItem[] = []
    const fullPropertyFilter: TFilterItem = {
      propertyId: {
        in: [],
      },
    }

    structures.forEach(property => {
      const propertyId = +property.id
      const fullBuildingIds: number[] = []

      const buildings = property.buildings.filter(building => building.checked)

      if (property.checked && !buildings.length) {
        fullPropertyFilter.propertyId.in.push(+property.id)
      } else if (property.checked) {
        buildings.forEach(building => {
          const units = building.units.filter(unit => unit.checked)
          if (building.checked && !units.length) {
            fullBuildingIds.push(+building.id)
          } else {
            filters.push({
              propertyId: {
                in: [propertyId],
              },
              buildingId: {
                in: [+building.id],
              },
              unitId: {
                in: units.map(unit => +unit.id),
              },
            })
          }
        })
      }

      if (fullBuildingIds.length) {
        filters.push({
          propertyId: {
            in: [propertyId],
          },
          buildingId: {
            in: fullBuildingIds,
          },
        })
      }
    })

    if (fullPropertyFilter.propertyId.in.length) {
      filters.push(fullPropertyFilter)
    }

    return filters
  }

  const clearSelections = () => {
    setStructures(structures =>
      structures.map(property => ({
        ...property,
        checked: false,
        containsSelected: false,
        buildings: property.containsSelected
          ? property.buildings.map(building => ({
              ...building,
              checked: false,
              containsSelected: false,
              units: building.containsSelected
                ? building.units.map(unit => ({
                    ...unit,
                    checked: false,
                  }))
                : building.units,
            }))
          : property.buildings,
      })),
    )
  }

  return {
    structures,
    setStructures,
    getSelectedIds,
    clearSelections,
  }
}

export default useStructuresFilter
