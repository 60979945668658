import './StaffOverview.style.scss'

import Breadcrumbs from '../../components/Breadcrumbs'
import {useLocation, useParams} from 'react-router-dom'
import Spinner from '../../components/Spinner/Spinner'
import {CrumbProps} from '../../components/Breadcrumbs/Crumb'
import React, {FC, useMemo} from 'react'

type StaffOverviewBreadcrumbsProps = {
  loading?: boolean
  staffName?: string
  propertyName?: string
}

const StaffOverviewBreadcrumbs: FC<StaffOverviewBreadcrumbsProps> = ({
  loading,
  staffName = 'Unknown',
  propertyName = 'Property',
}) => {
  const {propertyId} = useParams<{propertyId?: string}>()
  const location = useLocation()
  const currentPath = location.pathname
  const isReportsSection = currentPath.split('/')[1] === 'reports'

  const breadcrumbs = useMemo<CrumbProps[]>(() => {
    if (propertyId) {
      return [
        {name: 'Properties', url: '/properties/'},
        {
          name: propertyName,
          url: `/properties/${propertyId}/overview`,
        },
        {name: 'Staff', url: `/properties/${propertyId}/staff`},
        {name: staffName, url: currentPath},
        {name: 'Overview', url: ''},
      ]
    }

    return [
      {name: 'People', url: isReportsSection ? '/reports/people/' : '/people/'},
      {
        name: 'Staff',
        url: isReportsSection ? '/reports/people/staff/' : '/people/staff',
      },
      {
        name: staffName || '',
        url: isReportsSection
          ? `/reports/people/staff/${currentPath.split('/')[4]}`
          : `/people/staff/${currentPath.split('/')[3]}`,
      },
      {name: 'Overview', url: ''},
    ]
  }, [currentPath, isReportsSection, propertyId, propertyName, staffName])

  if (loading) {
    return <Spinner />
  }

  return <Breadcrumbs showBack={true} crumbs={breadcrumbs} />
}

export default React.memo(StaffOverviewBreadcrumbs)
