import {useMutation} from '@apollo/client'
import {useCallback, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {INVITE_VENDOR} from '../../data/graphql/mutations/people'
import {
  TInviteVendorResponse,
  TInviteVendorVariables,
} from '../../data/graphql/mutations/people/types'
import useLockAccesses from '../../hooks/useLockAccesses'
import useToast from '../../hooks/useToast'
import {TVendorUserLayoutSubmitData} from '../../layouts/VendorUserLayout/VendorUserLayout'

import useInstallerVendorUserOverview from './useInstallerVendorUserOverview'
import useServiceVendorUserOverview from './useServiceVendorUserOverview'
import useVendorUserContext from '../../layouts/VendorUserLayout/VendorUserContext/useVendorContext'
import {TPersonTypeCode} from '../../data/graphql/queries/common/types'

const useVendorUserOverview = () => {
  const navigate = useNavigate()
  const {showToast, showErrorToast, showInfoToast} = useToast()

  const {vendorId, userId} = useParams<{vendorId: string; userId?: string}>()
  const personId = userId ? Number(userId) : undefined

  const {person, profile} = useVendorUserContext()
  const {cancelInstallationTasks, assignInstallationTasks} =
    useInstallerVendorUserOverview()

  const serviceVendor = useServiceVendorUserOverview()
  const {refreshPersonAccesses} = useLockAccesses(
    profile ? Number(profile.personProfileId) : 0,
    TPersonTypeCode.V,
  )

  const [inviteVendorRequest] = useMutation<
    TInviteVendorResponse,
    TInviteVendorVariables
  >(INVITE_VENDOR)

  const [loading, setLoading] = useState(false)

  const isVendorIdentityCreated = !!person?.isIdentityCreated
  const yaleUserId = person?.miscInfo?.yaleLock?.userId

  const handleVendorInvite = useCallback(
    async (input: TVendorUserLayoutSubmitData) => {
      const payload: TInviteVendorVariables['input'] = {
        invitee: input.invitee,
        sender: input.sender,
        requestedThermostatAccesses: [],
        requestedLockAccesses: [],
        requestedCommonAreaLockAccesses: [],
        tasks: {
          serviceTasks: input.tasks.serviceTasks,
          installationTasks: input.tasks.installationTasks.devicesToInstall,
        },
      }

      payload.requestedLockAccesses = input.requestedLockAccesses.reduce<
        {
          installedDeviceId: number
          schedule?: string
        }[]
      >((result, {access, accessLevel, installedDeviceId}) => {
        if (accessLevel === 'app') {
          result.push({
            installedDeviceId,
            schedule: access?.schedule,
          })
        }
        return result
      }, [])

      payload.requestedCommonAreaLockAccesses =
        input.requestedPropertyCommonAreaAccess.map(
          ({propertyId, startDate, endDate}) => ({
            effectiveFrom: startDate,
            effectiveTo: endDate,
            propertyId: Number(propertyId),
          }),
        )

      return await inviteVendorRequest({
        variables: {
          input: payload,
        },
      })
    },
    [inviteVendorRequest],
  )

  const onServiceVendorSubmit = useCallback(
    async (input: TVendorUserLayoutSubmitData) => {
      let personProfileIdToRefetch = profile?.personProfileId
      const hasChanges =
        !!input.requestedLockAccesses.length ||
        !!input.deletedLockAccesses.length ||
        !!input.deletedPropertyCommonAreaAccess.length ||
        !!input.requestedPropertyCommonAreaAccess.length

      if (!hasChanges) {
        return showToast({
          type: 'info',
          title: 'No changes detected',
        })
      }

      setLoading(true)

      const hasAppAccessGranted = input.requestedLockAccesses.some(
        ({accessLevel}) => accessLevel === 'app',
      )

      await serviceVendor.revokeAccesses(input)

      if (yaleUserId && isVendorIdentityCreated) {
        await Promise.allSettled([
          serviceVendor.grantAppAccesses(input),
          serviceVendor.grantPinAccesses(input),
        ])
      } else {
        if (hasAppAccessGranted) {
          try {
            await handleVendorInvite(input)
            showInfoToast('App invitation', "Invitation has been sent'")
          } catch (e) {
            showErrorToast('App invitation', 'Invitation has not been sent')
          }
        }

        const createPinOnlyUserRequests: ReturnType<
          typeof serviceVendor.createYalePinUser
        >[] = []

        if (input.requestedLockAccesses.length) {
          createPinOnlyUserRequests.push(
            serviceVendor.createYalePinUser(
              input.invitee,
              input.requestedLockAccesses[0].lockId,
            ),
          )
        }

        if (input.requestedPropertyCommonAreaAccess.length) {
          createPinOnlyUserRequests.push(serviceVendor.createBrivoPinUser(input.invitee))
        }

        const [pinOnlyUser] = await Promise.all(createPinOnlyUserRequests)

        if (pinOnlyUser) {
          personProfileIdToRefetch = pinOnlyUser.personProfileId
          await serviceVendor.grantPinAccesses(input, {
            personId: Number(pinOnlyUser.personId),
            personProfileId: Number(pinOnlyUser.personProfileId),
            yaleUserId: pinOnlyUser.yaleUserId,
          })
        }
      }

      await refreshPersonAccesses({
        personProfileId: Number(personProfileIdToRefetch),
        condition: {
          isActive: true,
          isDeleted: false,
        },
      })

      setLoading(false)

      navigate(`/people/vendors/${vendorId}`)
    },
    [
      serviceVendor,
      yaleUserId,
      isVendorIdentityCreated,
      refreshPersonAccesses,
      navigate,
      vendorId,
      showToast,
      handleVendorInvite,
      showInfoToast,
      showErrorToast,
    ],
  )

  const onInstallerVendorSubmit = useCallback(
    async (input: TVendorUserLayoutSubmitData) => {
      setLoading(true)

      if (!isVendorIdentityCreated) {
        try {
          await handleVendorInvite(input)
          showInfoToast('App invitation', 'Invitation has been sent')
        } catch (e) {
          console.log(e)
          showErrorToast('App invitation', 'Invitation has not been sent')
        }
      } else {
        await Promise.allSettled([
          assignInstallationTasks(input),
          cancelInstallationTasks(input),
        ])
      }

      setLoading(false)
      navigate(`/people/vendors/${vendorId}`)
    },
    [
      assignInstallationTasks,
      cancelInstallationTasks,
      handleVendorInvite,
      isVendorIdentityCreated,
      navigate,
      showErrorToast,
      showInfoToast,
      vendorId,
    ],
  )

  return {loading, onServiceVendorSubmit, onInstallerVendorSubmit}
}

export default useVendorUserOverview
