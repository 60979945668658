import {useQuery} from '@apollo/client'
import {
  TGetPersonByIdResponse,
  TGetPersonByIdVariables,
} from '../../data/graphql/queries/people/types'
import {GET_PERSON_BY_ID} from '../../data/graphql/queries/people'
import useToast from '../useToast'

const usePersonData = (personId?: string | number) => {
  const {showErrorToast} = useToast()

  const query = useQuery<TGetPersonByIdResponse, TGetPersonByIdVariables>(
    GET_PERSON_BY_ID,
    {
      skip: !personId,
      variables: {
        personId: Number(personId),
      },
      onError() {
        showErrorToast('Person query error', 'Failed to get person data')
      },
    },
  )

  return {
    query,
    loading: query.loading,
    data: query.data?.transactionalDb?.person,
  }
}

export default usePersonData
