import {useContext, useMemo} from 'react'
import {AuthContext} from '../contexts/AuthContext'

const useCurrentProfile = () => {
  const {personProfile} = useContext(AuthContext)

  const result = useMemo(() => {
    if (!personProfile) {
      return {
        id: 0,
        personTypeId: 0,
        residentTypeId: 0,
        tenantUuid: '',
        propertyId: 0,
      }
    }

    return {
      id: Number(personProfile.personProfileId),
      personTypeId: Number(personProfile.personTypeId),
      residentTypeId: Number(personProfile.residentTypeId),
      tenantUuid: personProfile.tenantUuid,
      propertyId: Number(personProfile.propertyId),
    }
  }, [personProfile])

  return result
}

export default useCurrentProfile
