import {useMemo} from 'react'
import {arrayToObject} from '../../functions'
import {useQuery} from '@apollo/client'
import {GET_VENDOR_IDS_BY_PERSON_VENDORS} from '../../data/graphql/queries/people'
import {
  TGetVendorsByPersonVendorsResponse,
  TGetVendorsByPersonVendorsVariables,
} from '../../data/graphql/queries/people/types'

const useVendorsByPersonVendorIds = (personIds: number[] = []) => {
  const query = useQuery<
    TGetVendorsByPersonVendorsResponse,
    TGetVendorsByPersonVendorsVariables
  >(GET_VENDOR_IDS_BY_PERSON_VENDORS, {
    skip: !personIds.length,
    variables: {
      filter: {
        isDeleted: {
          equalTo: false,
        },
        personId: {
          in: personIds,
        },
      },
    },
  })

  const personVendors = query.data?.transactionalDb.allPersonVendors.nodes

  const vendorByPersonVendorId = useMemo(() => {
    if (!personVendors) {
      return {}
    }

    return arrayToObject(personVendors, 'personId')
  }, [personVendors])

  return {
    loading: query.loading,
    data: personVendors,
    vendorByPersonVendorId,
  }
}
export default useVendorsByPersonVendorIds
