import {FC} from 'react'
import './SidebarMenu.style.scss'
import SidebarMenuItem from './SidebarMenuItem'
import {SidebarMenuProps} from './SidebarMenu'
import useUserAccess from '../../hooks/useUserAccess'

const defaultProps: SidebarMenuProps = {}

const DashboardMenu: FC<SidebarMenuProps> = (props: SidebarMenuProps) => {
  props = {...defaultProps, ...props}

  const {properties} = useUserAccess()

  return (
    <nav data-testid={'SidebarMenu'}>
      <ul className={'sidebar-menu'}>
        <li>
          <SidebarMenuItem link={'/overview'} label={'Overview'} icon={'layout'} />
        </li>
        <li>
          <SidebarMenuItem link={'/people'} label={'People'} icon={'users'} />
        </li>
        {/* <li>
          <SidebarMenuItem
            link={'/roles-and-permissions'}
            label={'Roles & Permissions'}
            icon={'sliders'}
          />
        </li> */}
        <li>
          <SidebarMenuItem
            link={'/properties'}
            label={'Properties'}
            icon={'map-pin'}
            badge={properties.length || '-'}
          />
        </li>
        <li>
          <SidebarMenuItem
            link={'/activity-logs'}
            label={'Activity Logs'}
            icon={'activity'}
          />
        </li>
        <li>
          <SidebarMenuItem link={'/reports'} label={'Reports'} icon={'file-text'} />
        </li>

        <li>
          <SidebarMenuItem link={'/devices'} label={'Devices'} icon={'lock'} />
        </li>
      </ul>
    </nav>
  )
}

export default DashboardMenu
