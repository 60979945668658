export enum PersonType {
  UNKNOWN = 0,
  TENANT = 1,
  RESIDENT = 2,
  VENDOR = 3,
  EMPLOYEE = 4,
  CONTRACTOR = 5,
  PROPERTY = 6,
  GUEST = 7,
  BUILDING = 8,
  SELF_GUIDED = 9,
  DEV_ADMIN = 10,
}
