import './Overview.style.scss'

import React, {useContext, useEffect, useMemo, useState} from 'react'
import Section from '../../components/Grid/Section'
import Metrics from './Metrics'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../ScreenCrash/CrashScreen'
import Transactions from './Transactions'
import InputSelect from '../../components/InputSelect'
import useAvailableProperties from '../../hooks/data/useAvailableProperties'
import useAvailableProfiles from '../../hooks/data/useAvailableProfiles'
import {AuthContext} from '../../contexts/AuthContext'
import useToast from '../../hooks/useToast'
import {PersonType} from '../../data/graphql/queries/enums'

const Overview = () => {
  const {showErrorToast} = useToast()
  const profiles = useAvailableProfiles()
  const {data: properties} = useAvailableProperties()
  const {personProfile, setPersonProfile} = useContext(AuthContext)

  const [selectedProfileId, setSelectedProfileId] = useState(
    personProfile?.personProfileId || '',
  )

  const profilesOptions = useMemo<
    {
      label: string
      value: string
    }[]
  >(() => {
    if (!properties || !profiles.data) {
      return []
    }

    return profiles.data.map(({propertyId, personTypeId, personProfileId}) => {
      const property = properties.find(({id}) => +id === +propertyId)
      const suffix = +personTypeId === PersonType.DEV_ADMIN ? '(Admin)' : ''
      const name = property?.name || 'Unknown'

      return {
        value: personProfileId,
        label: `${name} ${suffix}`,
      }
    })
  }, [profiles.data, properties])

  useEffect(() => {
    if (
      selectedProfileId &&
      personProfile?.personProfileId &&
      selectedProfileId !== personProfile?.personProfileId
    ) {
      const newProfile = profiles.data?.find(
        ({personProfileId}) => +personProfileId === +selectedProfileId,
      )

      if (!newProfile) {
        showErrorToast('Person profile error', 'Failed to set valid person profile')
      }

      setPersonProfile(newProfile || null)
    }
  }, [
    personProfile?.personProfileId,
    profiles.data,
    selectedProfileId,
    setPersonProfile,
    showErrorToast,
  ])

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'Overview'} data-testid={'Overview'}>
        <Section spacing='md'>
          <div className='header'>
            <h3 className={'color-black overview-title'}>Overview</h3>

            <InputSelect
              id={'profile-select'}
              allowNull={false}
              value={selectedProfileId || ''}
              options={profilesOptions}
              onValueChange={setSelectedProfileId}
            />
          </div>
        </Section>

        <Metrics />

        <Section>
          <h4 className='black'>Your failed pin transactions</h4>
        </Section>

        <Transactions />
      </div>
    </ErrorBoundary>
  )
}

export default React.memo(Overview)
