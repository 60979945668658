import {Observable} from '@apollo/client'

const ApolloClientUtils = {
  toObservable: (excuteFn: () => Promise<unknown>) => {
    return new Observable(subscriber => {
      const execute = async () => {
        const result = excuteFn()
        const isPromise = typeof result.then === 'function'

        try {
          const value = isPromise ? await result : result

          if (subscriber.closed) {
            return
          }

          subscriber.next(value)
          subscriber.complete()
        } catch (err) {
          subscriber.error(err)
        }

        return subscriber
      }

      execute()
    })
  },
}

export default ApolloClientUtils
