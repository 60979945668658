// components/Sidebar/Sidebar.tsx

import React, {FC, useContext, useEffect, useState} from 'react'
import './Sidebar.style.scss'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {getAuth, signOut} from 'firebase/auth'
import {AuthContext} from '../../contexts/AuthContext'
import Dropdown from '../Dropdown'
import Menu from '../Menu'
import MenuItem from '../Menu/MenuItem'
import MenuDivider from '../Menu/MenuDivider'
import ProfilePicture from '../ProfilePicture'
import Icon from '../Icon'
import UserSettings from '../../views/UserSettings/UserSettings'
import Tooltip from '../Tooltip'
import useCurrentProfile from '../../hooks/useCurrentProfile'
import {PersonType} from '../../data/graphql/queries/enums'

export interface SidebarProps {
  open?: boolean
  menu?: React.ReactNode
  hideSidebar?(): void
}

const defaultProps: SidebarProps = {
  open: true,
}

const Sidebar: FC<SidebarProps> = (props: SidebarProps) => {
  props = {...defaultProps, ...props}
  const {hideSidebar, open} = props

  const navigate = useNavigate()
  const {pathname} = useLocation()
  const auth = getAuth()
  const profile = useCurrentProfile()
  const {setUser, user} = useContext(AuthContext)
  const [isUserSettingsOpen, setUserSettingsOpen] = useState(false)

  useEffect(() => {
    hideSidebar?.()
  }, [hideSidebar, pathname])

  const logout = async () => {
    await signOut(auth)
    setUser(null)
  }

  const userName = user ? `${user.firstName || '-'} ${user.lastName || '-'}` : '-'

  return (
    <>
      <div className={open ? 'drop-shadow-sidebar' : ''} onClick={hideSidebar} />
      <div
        className={`sidebar sidebar-${open ? 'open' : 'closed'}`}
        data-testid={'Sidebar'}
      >
        <div className={'sidebar-inner'}>
          <div className={'sidebar-header'}>
            <Link to={'/'}>
              <img className={'brand'} src={`/images/apthub-logo.svg`} alt={'AptHub'} />
            </Link>
          </div>
          <div className={'sidebar-body'}>
            {props.menu}
            <div className={'flex-grow-1'}></div>
            {/* <div>
              <Panel theme={'gray'}>
                <h5 className='h6-semibold-16'>Start New Action</h5>
                <p className='body-small-light-12'>
                  Etiam porta sem malesuada magna mollis euismod.
                </p>
                <Button width='wide' size='lg' theme={'primary'}>
                  Create new
                </Button>
              </Panel>
            </div> */}
          </div>
          <div className={'sidebar-footer'}>
            <Dropdown
              disableCloseEvents={isUserSettingsOpen}
              toggle={
                <div className={'user-menu-toggle'}>
                  <div>
                    <div>
                      <ProfilePicture size={'sm'} />
                    </div>
                    <div className='base-user-info'>
                      <Tooltip id={'userName'} tip={userName}>
                        <p className='desktop-label-semibold-14'>{userName}</p>
                      </Tooltip>

                      <Tooltip id={'email'} tip={auth?.currentUser?.email || '-'}>
                        <p className='body-small-light-12'>
                          {auth?.currentUser?.email || '-'}
                        </p>
                      </Tooltip>
                    </div>
                  </div>
                  <div>
                    <Icon icon={'chevron-down'} />
                  </div>
                </div>
              }
              direction={'up'}
            >
              <Menu>
                <MenuItem icon={'settings'} onClick={() => setUserSettingsOpen(true)}>
                  User Settings
                </MenuItem>
                {profile.personTypeId === PersonType.DEV_ADMIN && (
                  <MenuItem
                    icon={'home'}
                    onClick={() => {
                      navigate('/building-accounts')
                    }}
                  >
                    Building Accounts
                  </MenuItem>
                )}

                <MenuDivider />
                <MenuItem theme={'danger'} icon={'logout'} onClick={logout}>
                  Logout
                </MenuItem>
              </Menu>
            </Dropdown>
            <UserSettings
              isUserSettingsOpen={isUserSettingsOpen}
              setUserSettingsOpen={setUserSettingsOpen}
              onClick={e => e.stopPropagation()}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar
