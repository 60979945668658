import './Locks.style.scss'

import {useCallback, useContext, useEffect, useState} from 'react'
import Icon from '../../../components/Icon'
import Badge from '../../../components/Badge'
import {SortOptionsItem, useQueryOptions} from '../../../hooks/useQueryOptions'
import useDevices from '../../../hooks/data/useDevices'
import {capitalize, formatDateTime} from '../../../functions'
import {BadgeProps} from '../../../components/Badge/Badge'
import {DeviceTypes} from '../../../data/graphql/queries/common/types'
import BatteryCell from '../BatteryCell'
import {TActivityFilterFields} from '../../GuestsOverview/ActivityFilters/ActivityFilters'
import {ExportTableContext} from '../../../components/ExportTable/ExportTableContextProvider'
import {useGetEmptyTableMessage} from '../../../hooks/filters/useGetEmptyTableMessage'
import useTableSort from '../../../hooks/useTableSort'
import MasterPinCell from './MasterPinCell'
import {MasterPinStatus} from './MasterPinCell/MasterPinCell'

type TDeviceRecord = {
  rowKey: string
  name: React.ReactNode
  status: React.ReactNode
  location: string
  masterPin: React.ReactNode
  unit: string
  battery: React.ReactNode
  updatedAt: string
}

const sortOptions: Required<SortOptionsItem>[] = [
  {sortKey: 'name:asc', value: 'DEVICE_TYPE_ASC', label: 'Name Asc'},
  {sortKey: 'name:desc', value: 'DEVICE_TYPE_DESC', label: 'Name Desc'},
  {sortKey: 'location:asc', value: 'LOCATION_ASC', label: 'Location Asc'},
  {sortKey: 'location:desc', value: 'LOCATION_DESC', label: 'Location Desc'},
  {sortKey: 'status:asc', value: 'DEVICE_STATUS_CURRENT_ASC', label: 'Offline first'},
  {sortKey: 'status:desc', value: 'DEVICE_STATUS_CURRENT_DESC', label: 'Online first'},
  {sortKey: 'battery:asc', value: 'DEVICE_BATTERY_LEVEL_ASC', label: 'Battery Level Acs'},

  {
    sortKey: 'battery:desc',
    value: 'DEVICE_BATTERY_LEVEL_DESC',
    label: 'Battery Level Desc',
  },
  {sortKey: 'building:asc', value: 'BUILDING_NUMBER_ASC', label: 'Building Number Asc'},
  {
    sortKey: 'building:desc',
    value: 'BUILDING_NUMBER_DESC',
    label: 'Building Number Desc',
  },
  {sortKey: 'unit:asc', value: 'UNIT_NUMBER_ASC', label: 'Unit Number Asc'},
  {sortKey: 'unit:desc', value: 'UNIT_NUMBER_DESC', label: 'Unit Number Desc'},
  {
    sortKey: 'updatedAt:asc',
    value: 'DEVICE_STATUS_UPDATED_ASC',
    label: 'Updated Date Asc',
  },
  {
    sortKey: 'updatedAt:desc',
    value: 'DEVICE_STATUS_UPDATED_DESC',
    label: 'Updated Date Desc',
  },
]

const useLocksTable = () => {
  const {
    queryOptions,
    debouncedSearchTerm,
    upsertQueryOptions,
    onChangeNumberOfItems,
    setQueryOptions,
  } = useQueryOptions({
    page: 1,
    orderBy: ['DEVICE_STATUS_UPDATED_DESC'],
    searchTerm: '',
  })

  const tableSort = useTableSort(sortOptions, queryOptions.orderBy[0])
  const {setQuery} = useContext(ExportTableContext)
  const [tableData, setTableData] = useState<TDeviceRecord[]>([])
  const {devices, response, queryForDownloadTable} = useDevices(
    debouncedSearchTerm,
    queryOptions,
    {
      filter: {
        deviceTypeId: {
          in: [DeviceTypes.YALE_622, DeviceTypes.YALE_ASSURE_2],
        },
      },
    },
  )

  const totalCount =
    response?.data?.transactionalDb?.allDeviceInventoryViews?.totalCount || 0

  const renderLockName = useCallback(
    (deviceType: string | null) => (
      <div className='d-flex align-center'>
        <Icon icon={'lock'} theme='info' size='md' />
        <div className='lock-id label-small-regular-12 blacks'>{deviceType}</div>
      </div>
    ),
    [],
  )

  useEffect(() => {
    if (devices) {
      setTableData(
        devices.map(
          ({
            deviceBatteryLevel,
            unitNumber,
            buildingNumber,
            deviceLocation,
            deviceTypeId,
            deviceType,
            deviceStatusCurrent,
            installedDeviceId,
            masterPinStatus,
            lockId,
            ...device
          }) => {
            const updatedAt = device.deviceStatusUpdated
            const status = deviceStatusCurrent
            const unit = unitNumber && +unitNumber ? `Unit ${unitNumber}` : unitNumber

            return {
              rowKey: installedDeviceId,
              name: renderLockName(deviceType),
              status: getStatusBadge(status),
              location: deviceLocation || '—',
              masterPin: (
                <MasterPinCell
                  lockId={lockId || ''}
                  lockName={deviceLocation || ''}
                  unitNumber={unitNumber || ''}
                  pinStatus={masterPinStatus as MasterPinStatus}
                  installedDeviceId={Number(installedDeviceId)}
                  className='d-flex align-center'
                />
              ),
              unit: unit || '—',
              battery: deviceBatteryLevel ? (
                <BatteryCell level={+deviceBatteryLevel} />
              ) : (
                '—'
              ),
              updatedAt: updatedAt ? formatDateTime(new Date(updatedAt)) : '—',
            }
          },
        ),
      )
    } else {
      setTableData([])
    }
  }, [devices, renderLockName])

  useEffect(() => {
    if (queryOptions.orderBy?.[0] !== tableSort.value) {
      upsertQueryOptions(prev => ({...prev, orderBy: [tableSort.value]}))
    }
  }, [tableSort.value])

  const getStatusBadge = (status?: string | null) => {
    const badge = {
      label: capitalize(status),
      theme: 'info',
    }

    if (!status) {
      badge.label = 'Unknown'
      badge.theme = 'default'
    } else if (status.toLowerCase() === 'offline') {
      badge.label = 'Offline'
      badge.theme = 'danger'
    }

    return (
      <Badge size='sm' theme={badge.theme as BadgeProps['theme']}>
        {badge.label}
      </Badge>
    )
  }

  const emptyTableMessage = useGetEmptyTableMessage(queryOptions, {
    query: `Sorry, no matches found by "${queryOptions.searchTerm}".`,
    filter: `Sorry, no matches found by your filters.`,
    filtersAndQuery: `Sorry, no matches found by "${queryOptions.searchTerm}" and filters.`,
    default: 'Locks table is empty...',
  })

  const onSubmitFilter = useCallback(
    (filters: TActivityFilterFields) => {
      setQueryOptions(prev => ({
        ...prev,
        filters,
        page: 1,
      }))
    },
    [setQueryOptions],
  )

  const onTypeSearchField = useCallback(
    (text: string) => {
      upsertQueryOptions({
        page: 1,
        searchTerm: text,
      })
    },
    [upsertQueryOptions],
  )

  const onChangePage = useCallback(
    (page: number) => {
      upsertQueryOptions({
        page: page,
      })
    },
    [upsertQueryOptions],
  )

  const onChangeSortOrder = (value: string) => {
    tableSort.setSortValue(value)
    upsertQueryOptions(prev => ({...prev, orderBy: [value]}))
  }

  const dataForTableQuery = useCallback(async () => {
    try {
      const query = await queryForDownloadTable()

      const devices = query.data?.transactionalDb?.allDeviceInventoryViews.nodes || []

      const tableData = devices.map(
        ({
          deviceBatteryLevel,
          unitNumber,
          buildingNumber,
          deviceLocation,
          deviceTypeId,
          deviceType,
          deviceStatusCurrent,
          masterPinStatus,
          ...device
        }) => {
          const updatedAt = device.deviceBatteryLevelUpdated || device.deviceStatusUpdated
          const status = deviceStatusCurrent
          const unit = unitNumber && +unitNumber ? `Unit ${unitNumber}` : unitNumber
          const battery = deviceBatteryLevel
            ? `${(+deviceBatteryLevel * 100).toFixed(2)}%`
            : '-'

          return Object.values({
            name: deviceType?.replace('august-connect', 'August Connect'),
            status: status ? status : '—',
            location: deviceLocation || '—',
            unit: unit || '—',
            battery,
            updatedAt: updatedAt ? formatDateTime(new Date(updatedAt)) : '—',
            masterPinStatus,
          })
        },
      )

      tableData.unshift([
        'Name',
        'Status',
        'Location',
        'Unit',
        'Battery Level',
        'Updated Date',
      ])

      return tableData
    } catch (error) {
      console.error(error)
    }
  }, [queryForDownloadTable])

  useEffect(() => {
    setQuery(dataForTableQuery as () => Promise<string[][]>)
  }, [dataForTableQuery, setQuery])

  return {
    loading: response.loading,
    totalCount,
    tableData,
    tableSort,
    sortOptions,
    queryOptions,
    emptyTableMessage,

    onChangePage,
    onSubmitFilter,
    onChangeSortOrder,
    onTypeSearchField,
    onChangeNumberOfItems,
  }
}

export default useLocksTable
