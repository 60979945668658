import './Thermostats.style.scss'

import {useCallback, useContext, useEffect, useState} from 'react'
import Icon from '../../../components/Icon'
import {SortOptionsItem, useQueryOptions} from '../../../hooks/useQueryOptions'
import {capitalize, celsiusToFahrenheit, formatDateTime} from '../../../functions'
import {TActivityFilterFields} from '../../GuestsOverview/ActivityFilters/ActivityFilters'
import {ExportTableContext} from '../../../components/ExportTable/ExportTableContextProvider'
import {useGetEmptyTableMessage} from '../../../hooks/filters/useGetEmptyTableMessage'
import useThermostats from '../../../hooks/data/useThermostats'
import useTableSort from '../../../hooks/useTableSort'

type TDeviceRecord = {
  name: React.ReactNode
  temperature: React.ReactNode
  location: string
  unit: string
  updatedAt: string
  mode: React.ReactNode
  heat: string
  cool: string
  humidity: string
}

const sortOptions: Required<SortOptionsItem>[] = [
  {sortKey: 'unit:asc', value: 'UNIT_NUMBER_ASC', label: 'Unit Number Asc'},
  {sortKey: 'unit:desc', value: 'UNIT_NUMBER_DESC', label: 'Unit Number Desc'},
  {sortKey: 'heat:asc', value: 'HEAT_CELSIUS_SETTING_ASC', label: 'Heat Setting Asc'},
  {sortKey: 'heat:desc', value: 'HEAT_CELSIUS_SETTING_DESC', label: 'Heat Setting Desc'},
  {sortKey: 'cool:asc', value: 'COOL_CELSIUS_SETTING_ASC', label: 'Cool Setting Asc'},
  {sortKey: 'cool:desc', value: 'COOL_CELSIUS_SETTING_DESC', label: 'Cool Setting Desc'},
  {sortKey: 'mode:asc', value: 'MODE_ASC', label: 'Mode Asc'},
  {sortKey: 'mode:desc', value: 'MODE_DESC', label: 'Mode Desc'},
  {
    sortKey: 'temperature:asc',
    value: 'AMBIENT_TEMPERATURE_CELSIUS_ASC',
    label: 'Temperature Asc',
  },
  {
    sortKey: 'temperature:desc',
    value: 'AMBIENT_TEMPERATURE_CELSIUS_DESC',
    label: 'Temperature Desc',
  },
  {sortKey: 'humidity:asc', value: 'AMBIENT_HUMIDITY_PERCENT_ASC', label: 'Humidity Asc'},
  {
    sortKey: 'humidity:desc',
    value: 'AMBIENT_HUMIDITY_PERCENT_DESC',
    label: 'Humidity Desc',
  },
  {sortKey: 'updatedAt:asc', value: 'LAST_UPDATED_DT_ASC', label: 'Updated Date Asc'},
  {sortKey: 'updatedAt:desc', value: 'LAST_UPDATED_DT_DESC', label: 'Updated Date Desc'},
  {sortKey: 'location:asc', value: 'LOCATION_ASC', label: 'Location Asc'},
  {sortKey: 'location:asc', value: 'LOCATION_DESC', label: 'Location Desc'},
  {sortKey: 'name:asc', value: 'DEVICE_TYPE_ASC', label: 'Name Asc'},
  {sortKey: 'name:desc', value: 'DEVICE_TYPE_DESC', label: 'Name Desc'},
]

const useThermostatsTable = () => {
  const {
    queryOptions,
    debouncedSearchTerm,
    upsertQueryOptions,
    onChangeNumberOfItems,
    setQueryOptions,
  } = useQueryOptions({
    page: 1,
    orderBy: ['LAST_UPDATED_DT_DESC'],
    searchTerm: '',
  })

  const tableSort = useTableSort(sortOptions, queryOptions.orderBy[0])

  const [tableData, setTableData] = useState<TDeviceRecord[]>([])
  const {setQuery} = useContext(ExportTableContext)
  const {devices, response, queryForDownloadTable} = useThermostats(
    debouncedSearchTerm,
    queryOptions,
  )
  const totalCount =
    response?.data?.transactionalDb?.allThermostatStatusViews?.totalCount || 0

  const displayTemperature = (value: number | null): string => {
    if (!value) return '—'

    const fahrenheit = celsiusToFahrenheit(value)
    return `${fahrenheit.toFixed(1)}°F`
  }

  useEffect(() => {
    if (devices) {
      setTableData(
        devices.map(device => {
          const {
            unitNumber,
            location,
            deviceType,
            ambientTemperatureCelsius,
            heatCelsiusSetting,
            coolCelsiusSetting,
            ambientHumidityPercent,
            lastUpdatedDt,
            mode,
          } = device
          const unit = unitNumber && +unitNumber ? `Unit ${unitNumber}` : unitNumber

          return {
            name: (
              <div className='d-flex align-center'>
                <Icon icon={'thermometr'} theme='info' size='md' />
                <div className='thermostat-id label-small-regular-12 blacks'>
                  {deviceType}
                </div>
              </div>
            ),
            temperature: displayTemperature(ambientTemperatureCelsius),
            location: location || '—',
            unit: unit || '—',
            updatedAt: lastUpdatedDt ? formatDateTime(new Date(lastUpdatedDt)) : '—',
            mode: mode ? capitalize(mode) : '—',
            heat: displayTemperature(heatCelsiusSetting),
            cool: displayTemperature(coolCelsiusSetting),
            humidity: ambientHumidityPercent ? `${ambientHumidityPercent}%` : '—',
          }
        }),
      )
    } else {
      setTableData([])
    }
  }, [devices])

  const dataForTableQuery = useCallback(async () => {
    try {
      const query = await queryForDownloadTable()

      const devices = query.data?.transactionalDb?.allThermostatStatusViews.nodes || []

      const tableData = devices.map(
        ({unitNumber, buildingName, location, deviceType}) => {
          const unit = unitNumber && +unitNumber ? `Unit ${unitNumber}` : unitNumber
          const building =
            buildingName && +buildingName ? `Building ${buildingName}` : buildingName

          return Object.values({
            name: deviceType,
            temperature: '-',
            location: location || '—',
            building: building || '—',
            unit: unit || '—',
            battery: '—',
            updatedAt: '—',
          })
        },
      )

      tableData.unshift([
        'Name',
        'Temperature',
        'Location',
        'Building',
        'Unit',
        'Battery Level',
        'Updated Date',
      ])

      return tableData
    } catch (error) {
      console.error(error)
    }
  }, [queryForDownloadTable])

  useEffect(() => {
    setQuery(dataForTableQuery as () => Promise<string[][]>)
  }, [dataForTableQuery, setQuery])

  useEffect(() => {
    if (queryOptions.orderBy?.[0] !== tableSort.value) {
      upsertQueryOptions(prev => ({...prev, orderBy: [tableSort.value]}))
    }
  }, [tableSort.value])

  const onSubmitFilter = useCallback(
    (filters: TActivityFilterFields) => {
      setQueryOptions(prev => ({
        ...prev,
        filters,
        page: 1,
      }))
    },
    [setQueryOptions],
  )

  const onTypeSearchField = useCallback(
    (text: string) => {
      upsertQueryOptions({
        page: 1,
        searchTerm: text,
      })
    },
    [upsertQueryOptions],
  )

  const onChangePage = useCallback(
    (page: number) => upsertQueryOptions(prev => ({...prev, page})),
    [upsertQueryOptions],
  )

  const onChangeSortOrder = (value: string) => {
    tableSort.setSortValue(value)
    upsertQueryOptions(prev => ({...prev, orderBy: [value]}))
  }

  const emptyTableMessage = useGetEmptyTableMessage(queryOptions, {
    query: `Sorry, no matches found by "${queryOptions.searchTerm}".`,
    filter: `Sorry, no matches found by your filters.`,
    filtersAndQuery: `Sorry, no matches found by "${queryOptions.searchTerm}" and filters.`,
    default: 'Thermostats table is empty...',
  })

  return {
    loading: response.loading,
    tableData,
    tableSort,
    totalCount,
    sortOptions,
    queryOptions,
    emptyTableMessage,

    onChangePage,
    onSubmitFilter,
    onChangeSortOrder,
    onTypeSearchField,
    onChangeNumberOfItems,
  }
}

export default useThermostatsTable
