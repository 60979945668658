import './StaffOverview.style.scss'

import {toCommonDateFormat} from '../../functions'
import Button from '../../components/Button'
import Section from '../../components/Grid/Section'
import Panel from '../../components/Panel'
import DataGrid from '../../components/DataGrid'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../ScreenCrash/CrashScreen'
import Spinner from '../../components/Spinner/Spinner'
import AccessPoints from '../../layouts/VendorUserLayout/ServiceAccessPoints/ServiceAccessPoints'
import PhoneNumberModal from './PhoneNumberModal'
import ProfileHeader from '../../components/ProfileHeader'
import useStaffOverview from './useStaffOverview'
import StaffOverviewBreadcrumbs from './StaffOverviewBreadcrumbs'

const StaffOverview = () => {
  const {
    staffInfo,
    properties,
    accessPoints,
    loading,
    inviteLoading,
    isInviteModalOpen,

    onSubmit,
    onCloseModal,
    onPressInvite,
    onPressActivity,
    onChangeAccessPoint,
  } = useStaffOverview()

  if (loading) {
    return <Spinner />
  }

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'StaffOverview'} data-testid={'StaffOverview'}>
        <PhoneNumberModal
          isLoading={inviteLoading || loading}
          isOpen={isInviteModalOpen}
          onSubmit={onSubmit}
          onCloseRequest={onCloseModal}
        />

        <Section>
          <StaffOverviewBreadcrumbs />
        </Section>

        <ProfileHeader spacing={'section-md'} wrapperId={'contact-card'}>
          <ProfileHeader.PersonCard
            name={staffInfo?.name}
            subtitle={staffInfo?.position}
            badgeTheme={staffInfo?.isActive ? 'info' : 'default'}
            badgeLabel={staffInfo?.isActive ? 'Active' : 'Inactive'}
          />

          <ProfileHeader.InfoCard
            title={'Hire Date'}
            subtitle={toCommonDateFormat(staffInfo?.hireDate)}
          />
          <ProfileHeader.InfoCard title={'Email'} subtitle={staffInfo?.email} />
          <ProfileHeader.ButtonCard
            label='Activity'
            onClick={onPressActivity}
            className='activity-container'
          />
        </ProfileHeader>

        <Section spacing={'none'} id='asigned-properties'>
          <div className='d-flex space-between align-center'>
            <h4>Assigned Properties</h4>
          </div>

          <Panel theme={'white'}>
            <DataGrid
              id='staff-assigned-properties'
              loading={false}
              columns={[
                {key: 'name', name: 'Property Name'},
                {key: 'access', name: 'Access'},
                {key: 'city', name: 'City'},
                {key: 'state', name: 'State'},
                {key: 'zip', name: 'Zip Code'},
                {key: 'residents', name: 'Residents'},
                {key: 'staff', name: 'Staff'},
              ]}
              rows={properties}
            />
          </Panel>
        </Section>

        <Section id='access-points-section'>
          <AccessPoints
            noSchedule
            isVendorIdentityCreated={true}
            personId={Number(staffInfo?.personId)}
            defaultSchedule={accessPoints.values.schedule}
            value={accessPoints.values.accessPoints}
            onChange={onChangeAccessPoint}
          />
        </Section>

        <Section id='actions'>
          <Button onClick={onPressInvite}>Update user accesses</Button>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default StaffOverview
