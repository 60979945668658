import {useQuery} from '@apollo/client'
import {
  TGetServicePersonAccessesResponse,
  TGetServicePersonAccessesVariables,
} from '../../data/graphql/queries/people/types'
import {GET_SERVICE_PERSON_ACCESSES} from '../../data/graphql/queries/people'
import useToast from '../useToast'

const useServicePersonAccesses = (profileId?: number) => {
  const {showErrorToast} = useToast()
  const response = useQuery<
    TGetServicePersonAccessesResponse,
    TGetServicePersonAccessesVariables
  >(GET_SERVICE_PERSON_ACCESSES, {
    skip: !profileId,
    variables: {
      accessesFilter: {
        personProfileId: {
          equalTo: profileId,
        },
      },
      personProfileId: Number(profileId),
      condition: {
        isActive: true,
        isDeleted: false,
      },
    },
    onError() {
      showErrorToast('Request Error', 'Unable to Retrieve Person Accesses Data')
    },
  })

  return {
    profile: response?.data?.db?.profile,
    person: response?.data?.db?.profile.person,
    accesses: response?.data?.db?.accesses.nodes,
    response,
  }
}

export default useServicePersonAccesses
