import {Outlet, useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import Breadcrumbs from '../../components/Breadcrumbs'
import Column from '../../components/Grid/Column'
import Row from '../../components/Grid/Row'
import Section from '../../components/Grid/Section'
import Tabbar from '../../components/Tabbar'
import './ResidentsOverview.style.scss'
import CrashScreen from '../../views/ScreenCrash/CrashScreen'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import Spinner from '../../components/Spinner/Spinner'
import {formatPhoneNumber} from '../../functions'
import ProfileHeader from '../../components/ProfileHeader/ProfileHeader'
import useResidentPortalPerson from './useResidentPortalPerson'

const ResidentsOverview = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const pathNodes = location.pathname.split('/')
  const [searchParams] = useSearchParams()

  const panel = pathNodes[pathNodes.length - 1]
  const unitId = searchParams.get('unitId')
  const leaseId = searchParams.get('leaseId')
  const resident = useResidentPortalPerson()

  const breadcrumbs = [
    {name: 'People', url: '/people/'},
    {name: 'Residents', url: '/people/residents'},
    {
      name: resident.data?.personName || '',
      url: `/people/residents/${
        resident.data?.personProfileId || ''
      }/overview?unitId=${unitId}&leaseId=${leaseId}`,
    },
    {name: 'Overview', url: ''},
  ]

  const onPressActivity = () => {
    navigate(
      encodeURI(
        `/activity-logs/locks?limit=10&page=1&orderBy=TIME_STAMP_ASC&searchTerm=${resident.data?.personName}&units=[${unitId}]`,
      ),
    )
  }

  const unitLabel = resident.data?.unitNumber ? 'Unit ' + resident.data?.unitNumber : ''
  const mobilePhone = formatPhoneNumber(resident.data?.mobilePhone)

  if (resident.loading) {
    return <Spinner />
  }

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'ResidentsOverview'} data-testid={'ResidentsOverview'}>
        <Section>
          <Breadcrumbs showBack goBackUrl='/people/residents' crumbs={breadcrumbs} />
        </Section>

        <ProfileHeader id='resident-personal-info' spacing={'section-md'}>
          <ProfileHeader.PersonCard
            name={resident.data?.personName}
            subtitle={resident.data?.propertyName}
            badgeTheme={'danger'}
            badgeLabel={unitLabel}
          />

          <ProfileHeader.InfoCard
            title={'Call'}
            subtitle={mobilePhone}
            className='phone-number-section'
          />
          <ProfileHeader.InfoCard
            title={'Email'}
            subtitle={resident.data?.email}
            className='email-section'
          />
          <ProfileHeader.ButtonCard
            label='Activity'
            onClick={onPressActivity}
            className='action-section'
          />
        </ProfileHeader>

        <Section id='resident-content' spacing='none'>
          <Row>
            <Column xs={'12'}>
              <Tabbar
                tabs={[
                  {label: 'Overview', key: 'overview'},
                  {label: 'App Metrics', key: 'metrics'},
                  {label: 'Payment History', key: 'payment'},
                  {label: 'Guests Information', key: 'guests'},
                ]}
                selected={panel}
                onTabChange={key => {
                  navigate(`${key}?${searchParams.toString()}`)
                }}
              />
            </Column>
          </Row>
        </Section>

        <Outlet />
      </div>
    </ErrorBoundary>
  )
}

export default ResidentsOverview
