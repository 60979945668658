import {useCallback, useMemo} from 'react'

import {isUnitNumber} from '../../functions/filters'
import {QueryOptions} from '../../models'
import {
  TThermostatsResponse,
  TThermostatsVariables,
} from '../../data/graphql/queries/devices/types'
import {useQuery} from '@apollo/client'
import {GET_THERMOSTATS} from '../../data/graphql/queries/devices'

import {client} from '../../data/graphql'
import {TThermostatsFilterFields} from '../../views/Devices/Thermostats/ThermostatsFilter'
import useUserAccess from '../useUserAccess'

const useThermostats = (
  searchTerm: string,
  options: Required<QueryOptions<TThermostatsFilterFields>>,
  vars?: TThermostatsVariables,
) => {
  const {properties} = useUserAccess()
  const variables = useMemo(() => {
    const filter: TThermostatsVariables['filter'] = {
      propertyId: {
        in: properties,
      },
      buildingId: {
        notEqualTo: Number(process.env.REACT_APP_DEVS_BUILDING_ID),
      },
      and: [],
    }

    if (searchTerm) {
      filter[isUnitNumber(searchTerm) ? 'unitNumber' : 'deviceType'] = {
        includesInsensitive: searchTerm,
      }
    }

    if (options?.filters?.unitFilters) {
      filter.and?.push({
        or: options?.filters?.unitFilters,
      })
    }

    return {
      first: options.limit,
      offset: options.limit * (options.page - 1),
      orderBy: options?.orderBy,
      ...vars,
      filter: {
        ...filter,
        ...vars?.filter,
      },
    }
  }, [options, searchTerm, vars])

  const response = useQuery<TThermostatsResponse, TThermostatsVariables>(
    GET_THERMOSTATS,
    {
      variables,
    },
  )

  const queryForDownloadTable = useCallback(async () => {
    const copiedVariables: Partial<any> = {...variables}
    if ('first' in copiedVariables) {
      delete copiedVariables['first']
    }

    if ('offset' in copiedVariables) {
      delete copiedVariables['offset']
    }

    return await client.query<TThermostatsResponse, TThermostatsVariables>({
      query: GET_THERMOSTATS,
      variables: copiedVariables,
    })
  }, [variables])

  const devices = response?.data?.transactionalDb?.allThermostatStatusViews?.nodes

  return {
    devices,
    response,
    variables,
    queryForDownloadTable,
  }
}

export default useThermostats
