import './UnitDetails.style.scss'

import React, {FC} from 'react'
import {Link} from 'react-router-dom'

type Person = {
  leaseId?: string
  profileId: string
  personName: string
  personAccessId?: string
}

type TPeopleTileProps = {
  unitId?: string
  unitNumber?: string
  type: 'guest' | 'residents'
  people: Person[]
  className?: string
}

const PeopleTile: FC<TPeopleTileProps> = ({
  unitId,
  unitNumber,
  type,
  people,
  className = '',
}) => {
  const isGuestTile = type === 'guest'

  const getRedirectLink = ({profileId, personAccessId, leaseId = '0'}: Person) => {
    if (isGuestTile && personAccessId) {
      return `/people/guests/${personAccessId}`
    } else if (personAccessId) {
      return `/people/residents/${profileId}/overview?unitId=${unitId}&leaseId=${leaseId}`
    }

    return `/people/pending-invite?searchTerm=${unitNumber}`
  }

  const getRedirectLinkLabel = ({personAccessId}: Person) => {
    if (isGuestTile || personAccessId) {
      return 'View'
    }

    return 'Invite'
  }

  return (
    <div className={className}>
      <div className='content'>
        <h6>{isGuestTile ? 'Guests' : 'Residents'}</h6>

        {people.length ? (
          people.map(person => (
            <div key={person.profileId}>
              <span className='body-small-light-12'>{person.personName}</span>
              <Link className='label-small-regular-12' to={getRedirectLink(person)}>
                {getRedirectLinkLabel(person)}
              </Link>
            </div>
          ))
        ) : (
          <span className='body-small-light-12 gray'>
            There are no {type} for the unit
          </span>
        )}
      </div>
    </div>
  )
}

export default React.memo(PeopleTile)
