import {QueryHookOptions, useQuery} from '@apollo/client'
import {GET_PERSON_PROFILES} from '../../data/graphql/queries/people'
import {
  TGetPersonProfilesResponse,
  TGetPersonProfilesVariables,
} from '../../data/graphql/queries/people/types'
import useToast from '../useToast'

const usePersonProfiles = (
  options: QueryHookOptions<TGetPersonProfilesResponse, TGetPersonProfilesVariables>,
) => {
  const {showErrorToast} = useToast()
  const query = useQuery<TGetPersonProfilesResponse, TGetPersonProfilesVariables>(
    GET_PERSON_PROFILES,
    {
      onError() {
        showErrorToast('Profiles query error', 'Failed to get person profiles data')
      },
      ...options,
    },
  )

  const data = query.data?.db.profiles.nodes

  return {
    data,
    query,
    loading: query.loading,
  }
}

export default usePersonProfiles
