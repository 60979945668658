import {useQuery} from '@apollo/client'
import {useEffect, useMemo, useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import Breadcrumbs from '../../components/Breadcrumbs'
import Section from '../../components/Grid/Section'
import {GET_ALL_TYPES} from '../../data/graphql/queries/common'
import {TAllTypesResponse} from '../../data/graphql/queries/common/types'
import './GuestsOverview.style.scss'
import InputText from '../../components/InputText/InputText'
import Note from './Note/Note'
import {
  TGetPersonAccessByIdResponse,
  TGetPersonAccessByIdVariables,
} from '../../data/graphql/queries/people/types'
import ActivityLog from './ActivityLog'
import CrashScreen from '../ScreenCrash/CrashScreen'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import AccessSchedule from '../../components/AccessSchedule/AccessSchedule'
import Spinner from '../../components/Spinner/Spinner'
import ProfileHeader from '../../components/ProfileHeader'
import {GET_PERSON_ACCESS_BY_ID} from '../../data/graphql/queries/people'
import {TPerson} from '../../data/graphql/queries/entities'
import {
  getScheduleFromPersonAccess,
  isPersonAccessActive,
} from '../../functions/people.functions'
import RevokeGuestAccessButton from './RevokeGuestAccessButton'

const GuestsOverview = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const currentPath = location.pathname
  const isReportsSection = currentPath.split('/')[1] === 'reports'

  const routeParams = useParams<{personAccessId: string}>()
  const personAccessId = routeParams.personAccessId ? +routeParams.personAccessId : -1

  const allTypes = useQuery<TAllTypesResponse>(GET_ALL_TYPES)
  const accessQuery = useQuery<
    TGetPersonAccessByIdResponse,
    TGetPersonAccessByIdVariables
  >(GET_PERSON_ACCESS_BY_ID, {
    variables: {personAccessId},
  })

  const loading = accessQuery.loading
  const guestAccess = accessQuery.data?.transactionalDb.personAccess
  const residentType = guestAccess?.profile.personType.description
  const personId = guestAccess ? Number(guestAccess.personId) : -1
  const profileId = guestAccess ? Number(guestAccess.profile.id) : 0
  const unitId = guestAccess ? Number(guestAccess.unit.unitId) : -1

  const [formData, setFormData] = useState({
    type: '',
    schedule: getScheduleFromPersonAccess(guestAccess),
    property: '',
    notes: '',
    residentGuestOf: '',
    unit: '',
    isActive: !!guestAccess?.isActive,
  })

  const guestInfo = {
    name: getPersonName(guestAccess?.person),
    type: residentType || '',
    property: guestAccess?.building.property.propertyName || '',
  }

  useEffect(() => {
    if (guestAccess) {
      const {profile, person, building, unit} = guestAccess

      setFormData({
        type: profile.personType.description,
        property: building.property.propertyName,
        residentGuestOf: getPersonName(person.invitedBy) || '—',
        unit: unit.unitNumber,
        schedule: getScheduleFromPersonAccess(guestAccess),
        isActive: isPersonAccessActive(guestAccess),
        notes: 'Notes',
      })
    }
  }, [guestAccess])

  const breadcrumbsProps = useMemo(
    () => ({
      showBack: true,
      crumbs: [
        {name: 'People', url: isReportsSection ? '/reports/people' : '/people/'},
        {
          name: 'Guests',
          url: isReportsSection ? '/reports/people/guests' : '/people/guests',
        },
        {
          name: guestInfo.name,
          url: isReportsSection
            ? `/reports/people/guests/${personAccessId}`
            : `/people/guests/${personAccessId}`,
        },
        {name: 'Overview', url: ''},
      ],
    }),
    [guestInfo.name, personAccessId, isReportsSection],
  )

  function getPersonName(person?: Pick<TPerson, 'firstName' | 'lastName'>) {
    if (!person) {
      return ''
    }

    return `${person.firstName} ${person.lastName}`
  }

  if (!guestAccess && !loading) {
    navigate('/404')
    return null
  }

  if (allTypes.loading || loading) {
    return <Spinner />
  }

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className='GuestsOverview' data-testid='ResidentsOverview'>
        <Section>
          <Breadcrumbs {...breadcrumbsProps} />
        </Section>

        <Section spacing='none' id='actions-section'>
          <div className='d-flex align-center space-between actions'>
            <h3>Guest</h3>
            {!isReportsSection && (
              <div className='d-flex align-center'>
                <RevokeGuestAccessButton
                  unitNumber={formData.unit}
                  personProfileId={profileId}
                  unitId={unitId}
                />
              </div>
            )}
          </div>
        </Section>

        <ProfileHeader
          id='guest-info-section'
          spacing={'section-md'}
          wrapperId={'contact-card'}
        >
          <ProfileHeader.PersonCard
            hideBadge
            name={guestInfo?.name}
            subtitle='Guest'
            className='person-info-card'
          />

          <ProfileHeader.InfoCard title={'Type'} subtitle={guestInfo.type} />
          <ProfileHeader.InfoCard title={'Property'} subtitle={guestInfo.property} />
          <ProfileHeader.BadgeCard
            title={'Status'}
            badgeLabel={formData.isActive ? 'Active' : 'Inactive'}
            badgeTheme={formData.isActive ? 'success' : 'danger'}
          />
        </ProfileHeader>

        <Section spacing={'none'} id='overview-section'>
          <h4>Overview</h4>
          <form className={'guest-form'} data-testid={'GuestForm'}>
            <InputText
              disabled
              inputTestId='guest-type-input'
              id={'input-type'}
              type={'text'}
              label={'Type'}
              theme={'white'}
              placeholder={'Type'}
              value={formData.type}
            />
            <InputText
              disabled
              inputTestId='guest-property-input'
              id={'input-property'}
              type={'text'}
              label={'Property'}
              theme={'white'}
              placeholder={'Property'}
              value={formData.property}
            />
            <InputText
              disabled
              id={'input-guest-of'}
              type={'text'}
              label={'Resident Guest Of'}
              theme={'white'}
              placeholder={'Resident Guest Of'}
              value={formData.residentGuestOf}
            />
            <InputText
              disabled
              inputTestId='guest-unit-input'
              id={'input-unit'}
              type={'text'}
              label={'Unit'}
              theme={'white'}
              placeholder={'Unit'}
              value={formData.unit}
            />
          </form>

          <AccessSchedule disabled schedule={formData.schedule} />
        </Section>

        <Section spacing={'none'} id='notes-section'>
          <h4>Notes</h4>
          <Note name={getPersonName(guestAccess?.person)} />
        </Section>

        {guestAccess ? (
          <ActivityLog personId={personId} unitId={Number(guestAccess.unit.unitId)} />
        ) : null}
      </div>
    </ErrorBoundary>
  )
}

export default GuestsOverview
