import {useCallback, useMemo} from 'react'
import {useParams} from 'react-router-dom'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import VendorUserLayout from '../../layouts/VendorUserLayout'
import {TVendorUserLayoutSubmitData} from '../../layouts/VendorUserLayout/VendorUserLayout'
import CrashScreen from '../ScreenCrash/CrashScreen'
import useVendorUserOverview from './useVendorUserOverview'
import {InstallerContextProvider} from '../../layouts/VendorUserLayout/InstallerContext'
import VendorUserContextProvider from '../../layouts/VendorUserLayout/VendorUserContext'
import useVendorData from '../../hooks/data/useVendorData'
import usePersonData from '../../hooks/data/usePersonData'

const VendorUserOverview = () => {
  const {vendorId: vendorID, userId} = useParams()
  const vendorId = Number(vendorID)
  const personId = userId ? Number(userId) : 0
  const person = usePersonData(personId)

  const {loading, onInstallerVendorSubmit, onServiceVendorSubmit} =
    useVendorUserOverview()

  const {data: vendor, loading: vendorLoading} = useVendorData(vendorId)

  const breadcrumbs = useMemo(() => {
    const result = [
      {name: 'People', url: '/people/'},
      {name: 'Vendors', url: '/people/vendors'},
      {name: vendor?.vendorName || 'Vendor', url: '/people/vendors/' + vendorId},
    ]

    if (person.data) {
      result.push({
        name: `${person.data.firstName} ${person.data.lastName}`,
        url: `/people/vendors/${vendorId}/user/${personId}}`,
      })
    }

    return result
  }, [person, personId, vendor?.vendorName, vendorId])

  const onSubmit = useCallback(
    (input: TVendorUserLayoutSubmitData) => {
      if (input.vendorType === 'service') {
        onServiceVendorSubmit(input)
      } else {
        onInstallerVendorSubmit(input)
      }
    },
    [onServiceVendorSubmit, onInstallerVendorSubmit],
  )

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <InstallerContextProvider>
        <VendorUserLayout
          isEditing
          loading={loading || vendorLoading}
          userId={personId}
          vendorId={vendorId}
          breadCrumbs={breadcrumbs}
          onSubmit={onSubmit}
        />
      </InstallerContextProvider>
    </ErrorBoundary>
  )
}

// TODO: refactor this. Straigtforward update to let Tejaswi test it
const VendroUserOverviewWithContext = () => {
  const {vendorId: vendorID, userId} = useParams()
  const vendorId = Number(vendorID)
  const personId = userId ? Number(userId) : 0

  return (
    <VendorUserContextProvider personId={personId} vendorId={vendorId}>
      <VendorUserOverview />
    </VendorUserContextProvider>
  )
}

export default VendroUserOverviewWithContext
