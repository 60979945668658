import {useQuery} from '@apollo/client'
import {
  TAccessTypeCode,
  TPersonTypeCode,
} from '../../../data/graphql/queries/common/types'
import {GET_PORTAL_PEOPLE_COUNT} from '../../../data/graphql/queries/people'
import {
  TPortalPeopleCountResponse,
  TPortalPeopleCountVariables,
} from '../../../data/graphql/queries/people/types'
import useUserAccess from '../../../hooks/useUserAccess'

const useGuestsCount = () => {
  const {properties} = useUserAccess()

  const guestsQuery = useQuery<TPortalPeopleCountResponse, TPortalPeopleCountVariables>(
    GET_PORTAL_PEOPLE_COUNT,
    {
      skip: !properties.length,
      variables: {
        condition: {
          isPersonActive: true,
          isPersonAccessActive: true,
        },
        filter: {
          buildingId: {
            notEqualTo: Number(process.env.REACT_APP_DEVS_BUILDING_ID),
          },
          personAccessCode: {
            in: [TAccessTypeCode.G, TAccessTypeCode.P],
          },
          personType: {
            notEqualTo: TPersonTypeCode.E,
          },
          propertyId: {
            in: properties,
          },
        },
      },
    },
  )

  return {
    loading: guestsQuery.loading,
    total: guestsQuery.data?.transactionalDb.people.totalCount || 0,
  }
}

export default useGuestsCount
