import './GuestsOverview.style.scss'

import React, {FC, useCallback, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Button from '../../components/Button'

import {useRevokeGuestUnitLockAcceses} from '../../hooks/useRevokeGuestUnitLockAcceses'
import ConfirmModal from '../../components/ConfirmModal'

type RevokeGuestAccessButtonProps = {
  unitId: number
  personProfileId: number
  unitNumber: string
}

const RevokeGuestAccessButton: FC<RevokeGuestAccessButtonProps> = ({
  personProfileId,
  unitId,
  unitNumber,
}) => {
  const navigate = useNavigate()
  const [isConfirmationOpen, setConfirmationOpenFlag] = useState(false)

  const {loading: revokeLoading, revokeLockAccesses} = useRevokeGuestUnitLockAcceses({
    unitId,
    personProfileId,
    cb: () => {
      navigate(-1)
    },
  })

  const openGuestDeleteConfirmation = useCallback(() => {
    setConfirmationOpenFlag(true)
  }, [])

  const closeGuestDeleteConfirmation = useCallback(() => {
    setConfirmationOpenFlag(false)
  }, [])

  return (
    <>
      <ConfirmModal
        isOpen={isConfirmationOpen}
        title={'Delete guest access to unit ' + unitNumber}
        message={
          <p>
            <p>This action will delete guest user access to current unit.</p>
            <p>Are you sure you want to proceed?</p>
          </p>
        }
        confirmLabel='Yes, delete'
        onCancel={closeGuestDeleteConfirmation}
        onConfirm={revokeLockAccesses}
      />

      <Button
        size='md'
        theme={'danger-outline'}
        disabled={revokeLoading}
        onClick={openGuestDeleteConfirmation}
      >
        {revokeLoading ? 'Loading...' : 'Delete access'}
      </Button>
    </>
  )
}

export default React.memo(RevokeGuestAccessButton)
