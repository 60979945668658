import './Thermostats.style.scss'

import Column from '../../../components/Grid/Column'
import Panel from '../../../components/Panel'
import Row from '../../../components/Grid/Row'
import Section from '../../../components/Grid/Section'
import DataGrid from '../../../components/DataGrid'
import Paginator from '../../../components/Paginator'
import Search from '../../../layouts/People/Search/Search'
import SearchFilterInput from '../../../layouts/People/Search/SearchFilterInput'
import SearchFilters from '../../../layouts/People/Search/SearchFilters'
import SearchSortBy from '../../../layouts/People/Search/SearchSortBy'
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../../ScreenCrash/CrashScreen'
import TableFooter from '../../../components/TableFooter/TableFooter'
import TableNumberOfItems from '../../../components/TabelCountItems/TableNumberOfItems'
import ThermostatsFilter from './ThermostatsFilter'
import useThermostatsTable from './useThermostatsTable'

const Thermostats = () => {
  const table = useThermostatsTable()

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'Thermostats'} data-testid={'ThermostatsView'}>
        <Section>
          <Row>
            <Column>
              <>
                <Search>
                  <SearchFilterInput
                    placeholder='Search locks'
                    value={table.queryOptions.searchTerm || ''}
                    onValueChange={table.onTypeSearchField}
                  />
                  <SearchFilters
                    filter={ThermostatsFilter}
                    initialValue={table.queryOptions.filters}
                    onSubmit={table.onSubmitFilter}
                  />
                  <SearchSortBy
                    value={table.queryOptions.orderBy[0] || ''}
                    options={table.sortOptions}
                    onChange={table.onChangeSortOrder}
                  />
                </Search>
                <Panel theme={'white'}>
                  <DataGrid
                    selectedColumn={table.tableSort.column}
                    selectedColumnChange={table.tableSort.setSortColumn}
                    order={table.tableSort.order}
                    emptyTableComponent={table.emptyTableMessage}
                    columns={[
                      {key: 'name', name: 'Device Name', sortable: true},
                      {key: 'location', name: 'Location', sortable: true},
                      {key: 'temperature', name: 'Temp.', sortable: true},
                      {key: 'mode', name: 'Mode', sortable: true},
                      {key: 'heat', name: 'Heat', sortable: true},
                      {key: 'cool', name: 'Cool', sortable: true},
                      {key: 'humidity', name: 'Humidity', sortable: true},
                      {key: 'unit', name: 'Unit', sortable: true},
                      {key: 'updatedAt', name: 'Updated', sortable: true},
                    ]}
                    rows={table.tableData}
                    loading={table.loading}
                  />
                </Panel>

                <TableFooter itemCount={table.totalCount}>
                  <Paginator
                    itemCount={table.totalCount}
                    perPage={table.queryOptions.limit}
                    currentPage={table.queryOptions.page}
                    onPageChange={table.onChangePage}
                  />
                  <TableNumberOfItems
                    value={table.queryOptions.limit}
                    onValueChange={table.onChangeNumberOfItems}
                  />
                </TableFooter>
              </>
            </Column>
          </Row>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default Thermostats
