import '../ResidentsOverview.style.scss'

import React, {useEffect, useMemo, useState} from 'react'
import Card from '../../../components/Card/Card'
import Column from '../../../components/Grid/Column'
import Hr from '../../../components/Hr'
import BarChart from '../../../components/BarChart'
import {BarChartData} from '../../../components/BarChart/BarChart'
import CrashScreen from '../../../views/ScreenCrash/CrashScreen'
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary'
import {subDays} from 'date-fns'
import {useQuery} from '@apollo/client'
import {ALL_ACTIVITY_LOGS_DATES} from '../../../data/graphql/queries/activityLogs'
import {
  TAllActivityLogsDatesResponse,
  TAllActivityLogsDatesVariables,
} from '../../../data/graphql/queries/activityLogs/types'
import useResidentPortalPerson from '../useResidentPortalPerson'

const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const UsageChart = () => {
  const [startTime, setStartTime] = useState(Date.now())
  const resident = useResidentPortalPerson()

  const response = useQuery<
    TAllActivityLogsDatesResponse,
    TAllActivityLogsDatesVariables
  >(ALL_ACTIVITY_LOGS_DATES, {
    variables: {
      filter: {
        timeStamp: {
          greaterThanOrEqualTo: startTime,
        },
      },
      condition: {
        personId: Number(resident.data?.personId),
      },
    },
    skip: !resident.data?.personId,
  })

  useEffect(() => {
    const startDate = new Date()
    startDate.setUTCHours(0)
    startDate.setUTCMinutes(0)

    setStartTime(subDays(startDate, 6).getTime())
  }, [])

  const chartData = useMemo<BarChartData[]>(() => {
    if (!response?.data?.transactionalDb.allLockActivityLogs.nodes?.length) {
      return []
    }

    const defaultChartValues = weekdays.reduce((result, _, index) => {
      const utcDate = new Date()
      utcDate.setUTCHours(0)
      utcDate.setUTCMinutes(0)
      const date = subDays(utcDate, index)
      const dateString = date.toDateString()

      result[dateString] = {
        key: dateString,
        name: weekdays[date.getDay()],
        value: 0,
      }

      return result
    }, {})

    response?.data?.transactionalDb.allLockActivityLogs.nodes?.forEach(({timeStamp}) => {
      const dateString = new Date(+timeStamp).toDateString()

      if (defaultChartValues[dateString]) {
        defaultChartValues[dateString].value += 1
      }
    }, {})

    return Object.values<BarChartData>(defaultChartValues).sort((a, b) => {
      const aDate = new Date(a.key)
      const bDate = new Date(b.key)

      if (aDate > bDate) {
        return 1
      } else if (aDate < bDate) {
        return -1
      }

      return 0
    })
  }, [response?.data?.transactionalDb.allLockActivityLogs.nodes])

  const averageValue = (
    chartData.reduce((result, current) => result + current.value, 0) / 7
  ).toFixed(2)

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <Card padding='sm'>
        <div className='graph'>
          <Column>
            <h6>Usage data</h6>
            <div className='body-small-light-12'>Last 7 days</div>

            <div className='d-flex time-in-app-statistic align-center'>
              <div className='page-statistic-26'>
                Average {averageValue} actions per day
              </div>
            </div>
            <Hr />

            <BarChart data={chartData} />
          </Column>
        </div>
      </Card>
    </ErrorBoundary>
  )
}

export default UsageChart
