import './VendorUserLayout.style.scss'

import {FC, useMemo} from 'react'
import {arrayToObject} from '../../functions'
import ConfirmModal from '../../components/ConfirmModal'
import useVendorAssignments, {TWorkAssignment} from '../../hooks/useVendorAssignments'
import usePropertyDevices from '../../components/PropertyDevices/usePropertyDevices'

import usePropertiesStructures from '../../hooks/data/usePropertiesStructures'
import {TDeviceItem} from '../../functions/devices.function'

type TConfirmTaskUpdatesProps = {
  userId?: number
  isOpen: boolean
  installationTasks?: {deviceInventoryId: string}[]
  assignmentsToUnassign?: {deviceWorkAssignmentId: string}[]
  onCancel: () => void
  onConfirm: () => void
}

type TSummaryItem = {
  unitNumber: string
  devices: TDeviceItem[]
}

const ConfirmTaskUpdates: FC<TConfirmTaskUpdatesProps> = props => {
  const structures = usePropertiesStructures()
  const assignments = useVendorAssignments(props.userId)
  const {unitDevicesMap, unitReplacementDevices} = usePropertyDevices()

  const assignmentsByAssignmentId = useMemo<Record<string, TWorkAssignment>>(() => {
    return arrayToObject(Object.values(assignments.assignmentsByInventoryId), 'id')
  }, [assignments.assignmentsByInventoryId])

  const assignmentsToUnassign = props.assignmentsToUnassign!.map(
    ({deviceWorkAssignmentId}) => assignmentsByAssignmentId[deviceWorkAssignmentId],
  )

  const unitsByUnitId = useMemo(() => {
    return arrayToObject(structures.flattenData, 'unitId')
  }, [structures.flattenData])

  const devicesToInstall = useMemo(() => {
    return props.installationTasks!.reduce<Record<string, TSummaryItem>>(
      (result, {deviceInventoryId}) => {
        const [unitId, deviceTypeId, locationTypeId] = deviceInventoryId.split(':')
        const unitDevices = unitDevicesMap[unitId]

        const device = unitDevices.find(
          ({device, location}) =>
            device.typeId === deviceTypeId && location.typeId === locationTypeId,
        )

        const replacementDevice = Object.values(unitReplacementDevices[unitId]).find(
          ({device, location}) =>
            device.typeId === deviceTypeId && location.typeId === locationTypeId,
        )

        if (!result[unitId]) {
          result[unitId] = {
            unitNumber: unitsByUnitId[unitId]?.unitNumber,
            devices: [],
          }
        }

        if (device) {
          result[unitId].devices.push(device)
        } else if (replacementDevice) {
          result[unitId].devices.push(replacementDevice)
        }

        return result
      },
      {},
    )
  }, [props.installationTasks, unitDevicesMap, unitsByUnitId, unitReplacementDevices])

  const devicesToUnassign = useMemo(() => {
    return assignmentsToUnassign.reduce<Record<string, TSummaryItem>>((result, item) => {
      const unitDevices = unitDevicesMap[item.unitId]
      const device = unitDevices.find(
        ({device, location}) =>
          device.typeId === item.deviceTypeId &&
          location.typeId === item.deviceLocationTypeId,
      )

      if (!device) {
        return result
      }

      if (!result[item.unitId]) {
        result[item.unitId] = {
          unitNumber: unitsByUnitId[item.unitId]?.unitNumber,
          devices: [],
        }
      }

      result[item.unitId].devices.push(device)

      return result
    }, {})
  }, [assignmentsToUnassign, unitDevicesMap, unitsByUnitId])

  const summary = {
    toAssign: Object.values(devicesToInstall),
    toUnassign: Object.values(devicesToUnassign),
  }

  const renderSection = (message: string, changes: TSummaryItem[]) => {
    return (
      <div>
        <p className='desktop-label-semibold-14'>{message}</p>
        <ul>
          {changes.map(({unitNumber, devices}, index) => (
            <li key={index}>
              <p>Unit {unitNumber}:</p>

              <ul>
                {devices.map(({device, location}, index) => (
                  <li key={index}>
                    <p>
                      {location.description} {device.description}
                    </p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return (
    <ConfirmModal
      width={'auto'}
      isOpen={props.isOpen}
      title={'Review and confirm next changes'}
      message={
        <div className='VendorUserLayout-summary'>
          {!!summary.toUnassign.length &&
            renderSection(
              'Unassign installation tasks for these devices :',
              summary.toUnassign,
            )}

          {!!summary.toUnassign.length && !!summary.toAssign.length && (
            <div className='delimiter' />
          )}

          {!!summary.toAssign.length &&
            renderSection(
              'Assign installation tasks for these devices :',
              summary.toAssign,
            )}
        </div>
      }
      onCancel={props.onCancel}
      onConfirm={props.onConfirm}
    />
  )
}

ConfirmTaskUpdates.defaultProps = {
  installationTasks: [],
  assignmentsToUnassign: [],
}

export default ConfirmTaskUpdates
