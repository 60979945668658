import {useQuery} from '@apollo/client'
import {GET_AVAILABLE_PROPERTIES} from '../../data/graphql/queries/properties'
import {
  TAvailablePropertiesResponse,
  TAvailablePropertiesVariables,
} from '../../data/graphql/queries/properties/types'
import useToast from '../useToast'
import useAvailableProfiles from './useAvailableProfiles'

const useAvailableProperties = () => {
  const {showErrorToast} = useToast()
  const {properties} = useAvailableProfiles()

  const query = useQuery<TAvailablePropertiesResponse, TAvailablePropertiesVariables>(
    GET_AVAILABLE_PROPERTIES,
    {
      skip: !properties.length,
      variables: {
        filter: {
          isDeleted: {
            equalTo: false,
          },
          propertyId: {
            in: properties,
          },
        },
      },
      onError() {
        showErrorToast('Request Error', 'Unable to receive properties count')
      },
    },
  )

  return {
    query,
    loading: query.loading,
    data: query.data?.db.properties.nodes,
    totalCount: query.data?.db.properties.totalCount,
  }
}

export default useAvailableProperties
