import {useMemo, useRef} from 'react'
import {useQuery} from '@apollo/client'
import {GET_LEASE_OVERVIEW} from '../../data/graphql/queries/properties'
import {
  TLeaseOverviewResponse,
  TLeaseOverviewVariables,
} from '../../data/graphql/queries/properties/types'
import useToast from '../useToast'
import {getGreaterThanOrEqualFilter} from '../../functions/filters'
import {format} from 'date-fns'
import {
  LeaseTypes,
  TAllBuildingsWithUnitsResponse,
  TAllBuildingsWithUnitsVariables,
  UnitTypes,
} from '../../data/graphql/queries/common/types'
import {GET_BUILDINGS_WITH_UNITS} from '../../data/graphql/queries/common'
import useUserAccess from '../useUserAccess'

const useLeaseStats = () => {
  const today = useRef(new Date()).current
  const {showToast} = useToast()
  const {properties} = useUserAccess()

  const buildingsWithUnits = useQuery<
    TAllBuildingsWithUnitsResponse,
    TAllBuildingsWithUnitsVariables
  >(GET_BUILDINGS_WITH_UNITS, {
    variables: {
      filter: {
        propertyId: {
          in: properties,
        },
      },
    },
    skip: !properties.length,
  })

  const buildings = buildingsWithUnits.data?.transactionalDb.allBuildings.nodes
  const userBuildings = useMemo(() => {
    if (!buildings) {
      return []
    }

    return buildings.filter(
      ({buildingId}) => +buildingId !== Number(process.env.REACT_APP_DEVS_BUILDING_ID),
    )
  }, [buildings])

  const userUnitIds = useMemo(() => {
    const result: number[] = []

    userBuildings.forEach(({unitsByBuildingId}) => {
      unitsByBuildingId.nodes.forEach(({unitId}) => {
        result.push(+unitId)
      })
    })

    return result
  }, [userBuildings])

  const buildingIds = useMemo(() => {
    return userBuildings.map(({buildingId}) => +buildingId)
  }, [userBuildings])

  const leaseData = useQuery<TLeaseOverviewResponse, TLeaseOverviewVariables>(
    GET_LEASE_OVERVIEW,
    {
      variables: {
        unitsCondition: {
          isDeleted: false,
          isActive: true,
          unitTypeId: UnitTypes.A,
        },
        unitsFilter: {
          buildingId: {
            in: buildingIds,
          },
        },
        leasedUnitsFilter: {
          unitId: {
            in: userUnitIds,
          },
          and: [
            {
              or: [
                {
                  leaseTypeId: {
                    notEqualTo: LeaseTypes.MO,
                  },
                },
                {
                  leaseTypeId: {
                    isNull: true,
                  },
                },
              ],
            },
            {
              or: [
                {
                  moveOutDate: {
                    isNull: true,
                  },
                },
                {
                  moveOutDate: getGreaterThanOrEqualFilter(format(today, 'yyyy-LL-dd')),
                },
              ],
            },
          ],
        },
        leasedUnitsCondition: {
          isActive: true,
          isExpired: false,
        },
        overdueLeasesFilter: {
          unitId: {
            in: userUnitIds,
          },
          isActive: {
            equalTo: true,
          },
          currentBalance: {
            lessThan: 0,
          },
        },
      },
      skip: !userUnitIds.length,
      onError() {
        showToast({
          title: 'Request Error',
          message: 'Unable to Retrieve Lease Data',
          type: 'error',
        })
      },
    },
  )

  const result = useMemo(() => {
    const data = leaseData.data?.transactionalDb

    if (!data) {
      return {
        totalUnitsLeased: 0,
        ocupancy: 0,
        totalUnits: 0,
        overdueRent: 0,
      }
    }

    const ocupancy = (data.leasedUnits.totalCount / data.allUnits.totalCount) * 100
    const totalUnitsLeased = new Set(data.leasedUnits.nodes.map(({unitId}) => unitId))
      .size

    return {
      totalUnitsLeased,
      ocupancy: isNaN(ocupancy) ? 0 : Math.round(ocupancy),
      totalUnits: data.allUnits.totalCount,
      overdueRent: data.overdueLeases.nodes.reduce(
        (result, {currentBalance}) => +currentBalance + result,
        0,
      ),
    }
  }, [leaseData.data])

  return {
    loading: leaseData.loading || buildingsWithUnits.loading,
    counters: result,
  }
}

export default useLeaseStats
