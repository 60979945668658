import {useLazyQuery, useMutation} from '@apollo/client'
import {GET_PERSON_DEVICE_ACCESSES} from '../data/graphql/queries/people'
import {
  TGetPersonDeviceAccessesResponse,
  TGetPersonDeviceAccessesVariables,
} from '../data/graphql/queries/people/types'
import {
  TRevokeAccessToLocksResponse,
  TRevokeAccessToLocksVariables,
} from '../data/graphql/mutations/lock/types'
import {REVOKE_ACCESSES_TO_LOCKS} from '../data/graphql/mutations/lock'
import useToast from './useToast'
import {DeviceMaker, isCommonError} from '../data/graphql/types'
import {useCallback} from 'react'

type Props = {
  unitId: number
  personProfileId: number
  cb?: () => void
}

export const useRevokeGuestUnitLockAcceses = ({unitId, personProfileId, cb}: Props) => {
  const {showToast} = useToast()
  const [getPersonAccesses, personAccessesResponse] = useLazyQuery<
    TGetPersonDeviceAccessesResponse,
    TGetPersonDeviceAccessesVariables
  >(GET_PERSON_DEVICE_ACCESSES, {
    variables: {
      condition: {
        personProfileId,
        isActive: true,
        unitId,
      },
    },
  })

  const [revokeLockAccessesRequest, revokeLockAccessesResponse] = useMutation<
    TRevokeAccessToLocksResponse,
    TRevokeAccessToLocksVariables
  >(REVOKE_ACCESSES_TO_LOCKS)

  const getRevokeAccessesData = useCallback(async () => {
    const accessesResponse = await getPersonAccesses()
    const allPersonAccesses =
      accessesResponse.data?.transactionalDb?.allPersonAccesses?.nodes
    const installedDeviceIds =
      allPersonAccesses?.map(access => Number(access.installedDeviceId)) || []

    return {
      personProfileId,
      installedDeviceIds,
    }
  }, [getPersonAccesses, personProfileId])

  const revokeYaleAccesses = useCallback(
    async (personProfileId: number, installedDeviceIds: number[]) => {
      try {
        const revokeResponse = await revokeLockAccessesRequest({
          variables: {
            input: {
              installedDeviceIds,
              personProfileId,
              deviceMaker: DeviceMaker.YALE,
            },
          },
        })

        const data = revokeResponse.data?.lock.revokeAccessToSelectedLocks

        const isRevokeFailed = isCommonError(data) || data?.failed?.length

        if (isRevokeFailed) {
          throw new Error('Failed to revoke access to unit locks')
        }

        showToast({
          title: 'Unit access',
          message: 'Access to the unit has been revoked',
          type: 'info',
        })
      } catch (e) {
        showToast({
          title: 'Unit access',
          message: 'Failed to revoke access to unit locks',
          type: 'error',
        })
      }
    },
    [revokeLockAccessesRequest, showToast],
  )

  const revokeLockAccesses = useCallback(async () => {
    try {
      const {personProfileId, installedDeviceIds} = await getRevokeAccessesData()

      const revokeAcccesesList = [revokeYaleAccesses(personProfileId, installedDeviceIds)]

      await Promise.allSettled(revokeAcccesesList)

      cb?.()
    } catch (e) {
      showToast({
        title: 'Request Error',
        message: 'Failed to revoke user accesses',
        type: 'error',
      })
    }
  }, [getRevokeAccessesData, revokeYaleAccesses, showToast])

  return {
    revokeLockAccesses,
    loading: personAccessesResponse.loading || revokeLockAccessesResponse.loading,
  }
}
